const webConfigJSON = require("./config.js");
export const imgSearch = require("../assets/searchIcon.svg");
export const imgAddButton = require("../assets/add_button.svg");
export const imgMail = require("../assets/mail.svg");
export const imgEdit = require("../assets/edit.svg");
export const infoIcon = require("../assets/infoIcon.svg");
export const emptyImage = require("../assets/empty.png");
export const emptyListImage = require("../assets/emptyList.png");
export const sortIcon = require("../assets/sortIcon.svg");

export const headerTable = [
    {
        id: '1',
        text: webConfigJSON.headSNo,
        width: "87px",
        sortBy: "no"

    },
    {
        id: '2',
        text: webConfigJSON.headPatient,
        width: "142px",
        sortBy: "patient_name"
    },
    {
        id: '3',
        text: webConfigJSON.headStatusOfPatients,
        width: "132px",
        sortBy: "patient_status"
    },
    {
        id: '4',
        text: webConfigJSON.headCaregiver,
        note: webConfigJSON.noteCaregiver,
         width: "13vw",
    },
    {
        id: '5',
        text: webConfigJSON.headRelation,
         width: "9vw",
    },
    {
        id: '6',
        text: webConfigJSON.headNoOfDevices,
        note: webConfigJSON.noteNoOfDevices,
        width: "145px",
        },
        {
            id: '7',
            text: webConfigJSON.headPhoneNumber,
            note: webConfigJSON.notePhoneNumber,
            width: "10vw",
    },
    {
        id: '8',
        text: webConfigJSON.headEmail,
        note: webConfigJSON.noteEmail,
        width: "18vw",
    },
    {
        id: '9',
        text: webConfigJSON.headResendEmail,
        note: webConfigJSON.noteResendEmail,
        width: "9vw"
    },
]
export const mockPatientData = [
    {
        "patient_id": 4,
        "patient_name": "Jared Duncan",
        "patient_status": "Active",
        "caregivers": [
            {
                "caregiver_id": 20,
                "caregiver_name": "Celina Duncan",
                "relation_to_patient": "Daughter",
                "caregiver_email": "celinaduncan@example.com",
                "caregiver_phone_number": "1-547-821-5464",
                "add_patient_id": 4
            },
            {
                "caregiver_id": 21,
                "caregiver_name": "Mary Duncan",
                "relation_to_patient": "Nephew",
                "caregiver_email": "sravan35@yopmail.com",
                "caregiver_phone_number": "9493001746",
                "add_patient_id": 4
            }
        ]
    },
    {
        "patient_id": 5,
        "patient_name": "Stacey Stone",
        "patient_status": 'Expired',
        "caregivers": [
            {
                "caregiver_id": 22,
                "caregiver_name": "Patricia Stone",
                "relation_to_patient": "Spouse",
                "caregiver_email": "sravan35@yopmail.com",
                "caregiver_phone_number": "9493001746",
                "add_patient_id": 5
            },
            {
                "caregiver_id": 23,
                "caregiver_name": "Linda Stone",
                "relation_to_patient": "Daughter",
                "caregiver_email": "sravan35@yopmail.com",
                "caregiver_phone_number": "9493001746",
                "add_patient_id": 5
            }
        ]
    },
    {
        "patient_id": 6,
        "patient_name": "Kean Mars",
        "patient_status": 'Active',
        "caregivers": [
            {
                "caregiver_id": 24,
                "caregiver_name": "Patricia Stone",
                "relation_to_patient": "Spouse",
                "caregiver_email": "sravan35@yopmail.com",
                "caregiver_phone_number": "9493001746",
                "add_patient_id": 5
            },
        ]
    },
]