import React from "react";

// Customizable Area Start
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles'
export const configJSON = require("./config");
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import UnauthorizedHeader from "../../../components/src/UnauthorizedHeader";
import UnauthorizedFooter from "../../../components/src/UnauthorizedFooter";
import Toast from "../../../components/src/Toast";
import { Input, Button } from '@builder/component-library';

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderModalSuccess = () => {
    return (
      <Toast textMessage="Email Sent Successfully" isShow={this.state.modalSuccess}/>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', width: "100%"}}>
        <UnauthorizedHeader/>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', paddingTop: 10, paddingBottom: 10}}>
            {this.renderModalSuccess()}
          <Box sx={styles.formContainer}>
            <Typography style={{...styles.mainLabel, textAlign: 'center', marginTop: 20, fontFamily: "Lato"}}>{configJSON.labelTitle}</Typography>
   
                <FormContainer>
                  <div
                  style={{marginTop: 27,}}
                  >
                    <Typography style={styles.textLabel}>{configJSON.titleEmail}</Typography>
                    <Input
                      data-test-id={"txtInputEmail"}
                      keyboardType={configJSON.firstInputKeyboardStyle}
                      // inputContainerStyle={}
                      placeholder={configJSON.placeHolderEmail}
                      onChangeText={(value) => {
                        this.handleChangeEmailValue(value)
                      }}
                      error={this.state.emailError ? this.state.emailError : undefined}
                      errorStyle={{ color: '#DC2626', fontSize: 12 }}
                    />
                  </div>
                  <Box style={{ paddingTop: 30, paddingBottom: 20 }}>
                    <Button
                      data-test-id={"btnResetPassword"}
                      text={configJSON.btnTxtResetPassword}
                      textStyle={{fontFamily: "Lato", color: this.checkValidEmail()? "#fff" :  '#7C8E76' }}
                      onPress={this.handleForgotPassword}
                      loading={this.state.loading}
                      style={{backgroundColor: this.checkValidEmail() ? '#7C8E76' : '#D6DCD5'}}
                    />
                  </Box>
                    <Button
                      data-test-id={"btn-back-to-login"}
                      style={{...styles.removeBackground, alignSelf: 'center'}}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      text={configJSON.btnTxtLogin}
                      onPress={() => {this.goToLogin()}}
                    />
                </FormContainer>
          </Box>
            <ImageContainer>
              <img style={{...styles.image, objectFit: 'cover',}} src={require('../assets/loginImage.png')}/>
            </ImageContainer>
        </div>
        <UnauthorizedFooter data-test-id="footer" goToPP={this.goToPrivacyPolicy} goToTAC={this.goToTermsAndCondition}/>
      </div>
      // Customizable Area End
    );
  }

}

  // Customizable Area Start
  const styles = {
    formContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      height: "100%",

    },
    buttonActive: {
      backgroundColor: '#F2F4F1', padding: 6, paddingHorizontal: 12, borderBottomWidth: 1, borderBottomColor: "rgba(124, 142, 118, 0.50)"
    },
    buttonInactive: {
      backgroundColor: '#F2F4F1', padding: 6, paddingHorizontal: 12, borderWidth: 1, borderColor: "#F2F4F1"
    },
    buttonRoutingContainer: {
      display: 'flex', columnGap: 2, alignSelf: 'center'
    },
    mainLabel: {
      fontSize: 24,
      fontWeight: "700",
      letterSpacing: -0.12,
      color: "#343C32"
    },
    textModalSuccess: {
        fontFamily: "Inter",
        color: "#0F172A"
    },
    textLabel: {
      fontSize: 14,
      fontWeight: '700',
      color: '#343C32',
      fontFamily: "Lato"
    },
    image: {
      width: '100%',
      borderBottomLeftRadius: 45,
      aspectRatio: 1
    },
    removeBackground: {
      backgroundColor: "transparent" 
    },
    errorStyle: {
      color: "#DC2626",
    },
    checkBoxText: {
      fontSize: 16,
      color: "#343C32",
    },
    buttonsWrapper: {
      flex: 1,
    },
  }
  const ImageContainer = styled(Box)({
    display: 'flex',
    flex: 1,
    '@media (max-width: 1180px)' : {
    display: 'none'
    }
  });
  const FormContainer = styled(Box)({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    paddingLeft: "16px",
  paddingRight: "16px",
    alignSelf: 'center',
    "@media (max-width: 600px)": {
      width: "100%"
    },
  });
  // Customizable Area End