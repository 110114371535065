import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import MethodOverviewSection from './MethodOverviewSection.web';
import TreatmentDescSection from './TreatmentDescSection.web';
import SideEffectSection from './SideEffectSection.web';
import { IContentData, IImage } from "../../blocks/contentmanagement/src/ContentManagementController";

const Span = styled('span')({
  color: 'red',
});

const SectionTitle = styled(Typography)({
  fontSize: '26px',
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32'
});

function Psychotropic({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const terms = data?.attributes?.terms || [];
  const filteredTerms = terms.slice(1, 6);
  const treatment = filteredTerms.map(term => {
    const cleanDefinition = term?.definition?.replace(/<\/?ul>/g, '');
  
    return {
      heading: term?.term,
      desc: cleanDefinition?.split('<li>')
        .map(item => item.replace('</li>', '').trim())
        .filter(item => item),
      images: Array.isArray(term?.images) && term?.images.length
        ? term?.images.map(img => ({ url: img.url, type: img.type }))
        : undefined,
    };
  });
  
  console.log('Result:', treatment);
  const attributes = data?.attributes;
  const title = attributes?.title?.title;
  const desc = attributes?.title?.desc;
  const mainTitle = attributes?.terms?.[0]?.term;
  const definition = attributes?.terms?.[0]?.definition;
  const image = attributes?.terms?.[0]?.images?.[0];
  const highlightedTitle = title?.replace(
    /Anxiety\/Agitation\/Restlessness/g,
    '<span style="color: red;">Anxiety/Agitation/Restlessness</span>'
  );

  const term6 = terms[6] ?? {};
  const term7 = terms[7] ?? {};

  const sideEffects = {
    common: {
      title: term6?.term ?? '',
      items: (term6?.definition ?? '')
        .replace(/<\/?ul>/g, '')
        .split('<li>')
        .filter(item => item.trim() !== '')
        .map((item, index) => ({
          descImage: term6?.images?.[index]?.url ?? '',
          content: item.replace('</li>', '').trim()
        })) ?? []
    },
    serious: {
      title: term7?.term ?? '',
      items: (term7?.definition ?? '')
        .replace(/<\/?ul>/g, '')
        .split('<li>')
        .filter(item => item.trim() !== '')
        .map((item, index) => ({
          descImage: term7?.images?.[index]?.url ?? '',
          content: item.replace('</li>', '').trim()
        })) ?? []
    }
  };

  console.log(sideEffects)

  return (
    <>
      <ContentBreadcrumbs stacks='dashboard/medication/psychotropic' onNavigate={onNavigate} />
      <MethodOverviewSection
        title={
          <SectionTitle>
            <span style={{ color: "#343C32" }} dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
          </SectionTitle>
        }
        subtitle={desc}
        mainTitle={mainTitle}
        image={<img src={image?.url} alt={mainTitle} />}
        benefits={definition ? [{ definition }] : []}
      />
      <TreatmentDescSection treatment={treatment} />
      <SideEffectSection sideEffects={sideEffects} />
    </>
  );
}

export default Psychotropic;
