Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_catalogue/catalogues/";
exports.subCategoryAPIEndPoint = "bx_block_categories/categories/";
exports.subsubCategoryAPIEndPoint = "bx_block_categories/sub_categories/";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.footerFirstSentenceText = "Not familiar with a term?";
exports.footerSecondSentenceText = " Click here to find it in our dictionary.";

// Customizable Area End