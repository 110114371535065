import React from "react";
import { Typography, Box, Grid, Paper } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Diarrhea1 = require("../../blocks/contentmanagement/assets/diarrhea1.png");
const diarrhea2 = require("../../blocks/contentmanagement/assets/diarrhea2.png");
const medical1 = require("../../blocks/contentmanagement/assets/medical1.png");
const medical2 = require("../../blocks/contentmanagement/assets/medical2.png");
const medical3 = require("../../blocks/contentmanagement/assets/medical3.png");
const medical4 = require("../../blocks/contentmanagement/assets/medical4.png");
const medical5 = require("../../blocks/contentmanagement/assets/medical5.png");
const medical6 = require("../../blocks/contentmanagement/assets/medical6.png")
const nonmedical1 = require("../../blocks/contentmanagement/assets/nonmedical1.png");
const nonmedical2 = require("../../blocks/contentmanagement/assets/nonmedical2.png");
const nonmedical3 = require("../../blocks/contentmanagement/assets/nonmedical3.png");
const nonmedical4 = require("../../blocks/contentmanagement/assets/nonmedical4.png");
const nonmedical5 = require("../../blocks/contentmanagement/assets/nonmedical5.png");

const navbarStyles = {
    main: {
        padding: "20px",
    },
    dash: {
        color: "#96A591",
        fontStyle: "Lato",
    },
};

const style = {
    bigTitle: {
        fontFamily: "Lato !important",
        fontWeight: "700",
        color: "#586554",
        fontSize: { xs: "20px", sm: "26px" },
        textAlign: "center"
    },
    smallTitle: {
        fontFamily: "Lato !important",
        fontWeight: "400",
        color: "#586554",
        fontSize: { xs: "16px", sm: "22px" },
    },
}

const causesData = {
    nonMedical: [
        {
            title: 'Food',
            imgSrc: nonmedical1,
            definitions: ['Dairy (Lactose)', 'Sodas (Fructose)', 'Excess fruit'],
        },
        {
            title: 'Medications',
            imgSrc: nonmedical2,
            definitions: ['Some liquid medications', 'Antibiotics', 'Normal side effects of medications that are now too strong'],
        },
        {
            imgSrc: nonmedical3,
            definitions: ['Liquid food through a feeding tube'],
        },
        {
            imgSrc: nonmedical4,
            definitions: ['Overtreatment of constipation'],
        },
        {
            imgSrc: nonmedical5,
            definitions: ['Anxiety'],
        },
    ],
    medical: [
        { imgSrc: medical1, definition: 'Infections especially post antibiotic use' },
        { imgSrc: medical2, definition: 'Related to type of cancer' },
        { imgSrc: medical3, definition: 'Post radiation to GI tract' },
        { imgSrc: medical4, definition: 'Overflow incontinence from fecal impaction' },
        { imgSrc: medical5, definition: 'Bleeding from the intestine' },
        { imgSrc: medical6, definition: 'Aggravation of underlying irritable bowel' }
    ],
};

const firstStepsData = [
    { id: 1, text: 'Review all medications with the Hospice Team' },
    { id: 2, text: 'Review whether this is a new problem or aggravation of old problem prior to hospice' },
    { id: 3, text: 'Take a look at diet - eliminate dairy, sodas, and excess fruit' },
    { id: 4, text: 'Review whether the diarrhea started after prolonged bout of constipation - if yes, discuss with team' }
];

const medicationStrategies = [
    {
      id: 1,
      title: 'Anti-diarrhea medications are very effective:',
      descriptions: [
        "Imodium A-D. This is readily available over the counter. Use as instructed"
      ],
    },
    {
      id: 2,
      title: 'Make sure to stay hydrated with fluids that contain some salt',
      descriptions: [
        'If diet is not sugar restricted, Gatorade if very effective',
        'Can use Pedialyte, Broths',
        'If urinating normally, this is a good sign that staying hydrated',
      ],
    },
    {
      id: 3,
      title: "Have available Ointment/Salves for 'Sore Butt'",
      descriptions: [
        "Butt balm",
        "Baby wipes'",
        "A&D Ointment",
      ],
    },
  ];
  
const whenToCallHospice = [
    'Diarrhea persisting despite use of Imodium',
    'Feeling dehydrated despite fluid intake, or unable to take fluids because of nausea/vomiting',
    'Bloody bowel movements',
    "Increasing or new abdominal pain"
];

function Diarrhea() {
    return (
        <>
        <Box sx={{fontFamily:"Lato !important"}}>
            <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px", gap: "10px" }}>
                <ArrowBackIcon />
                <Typography sx={navbarStyles.dash}>Dashboard</Typography>
                <ChevronRightIcon sx={navbarStyles.dash} />
                <Typography sx={navbarStyles.dash}>Symptoms</Typography>
                <ChevronRightIcon sx={navbarStyles.dash} />
                <Typography sx={navbarStyles.dash}>Bowel Problems</Typography>
                <ChevronRightIcon sx={navbarStyles.dash} />
                <Typography sx={{ fontSize: "22px", color: "#0F172A", fontWeight: "700" }}>Diarrhea</Typography>
            </Box>
            <Box sx={{ background: "#F2F4F1", borderRadius: "8px", padding: { sm: "40px", xs: "20px" } }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={2} container justifyContent="center">
                        <img src={Diarrhea1} alt='image1' />
                    </Grid>
                    <Grid item xs={12} sm={8} textAlign='center'>
                        <Typography sx={style.smallTitle}>
                            There are both common 'non-medical' causes and medical causes of diarrhea
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} container justifyContent="center">
                        <img src={diarrhea2} alt='image2' />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ padding: { xs: '0px 20px', sm: '0px 130px' }, backgroundColor: '#fff', marginTop: "20px" }}>
                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Paper sx={{ padding: '20px', borderRadius: '8px', backgroundColor: '#F2F4F1', flexGrow: 1 }}>
                            <Typography sx={style.bigTitle}>Common 'Non-Medical' Causes</Typography>
                            {causesData.nonMedical.map((cause, index) => (
                                <Box key={index} sx={{ marginTop: '16px', background: "#FFFFFF", padding: "10px", borderRadius: "8px" }}>
                                    <Typography sx={{ color: "#586554", fontWeight: "700", fontSize: "26px", marginLeft: "95px" }}>{cause.title}</Typography>
                                    <Box display="flex" alignItems="center" sx={{ marginTop: '8px', gap: "10px" }}>
                                        <img src={cause.imgSrc} alt={cause.title} />
                                        <Box>
                                            {cause.definitions.map((definition, idx) => (
                                                <Box key={idx} display="flex" alignItems="center" sx={{ marginTop: '8px' }}>
                                                    {(cause.title === 'Food' || cause.title === 'Medications') && <ChevronRightIcon />}
                                                    <Typography sx={style.smallTitle}>
                                                        {definition}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Paper sx={{ padding: '20px', borderRadius: '8px', backgroundColor: '#F2F4F1', flexGrow: 1 }}>
                            <Typography sx={style.bigTitle}>Medical Causes</Typography>
                            {causesData.medical.map((item, index) => (
                                <Box key={index} display="flex" alignItems="center" sx={{ gap: "10px", marginTop: '16px', background: "#FFFFFF", padding: "10px", borderRadius: "8px" }}>
                                    <img src={item.imgSrc} alt="medical cause" />
                                    <Box display="flex" alignItems="center">
                                        <Typography style={{ marginLeft: "10px" }} sx={style.smallTitle}>
                                            {item.definition}
                                        </Typography>
                                    </Box>
                                    </Box>
                            ))}
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ padding: { xs: '20px', sm: '40px 130px' }, backgroundColor: '#F2F4F1', marginTop: '20px', borderRadius: '8px' }}>
                <Typography sx={style.bigTitle}>
                    What to Do First
                </Typography>
                {firstStepsData.map((item) => (
                    <Box key={item.id} display="flex" alignItems="center" sx={{ marginTop: '16px', padding: "10px", borderRadius: "8px", gap: "10px" }}>
                        <ChevronRightIcon />
                        <Typography sx={style.smallTitle}>
                            {item.text}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box sx={{ padding: { xs: '20px', sm: '40px 130px' }, backgroundColor: '#fff', marginTop: '20px' }}>
    <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper sx={{ padding: '20px', borderRadius: '8px', backgroundColor: '#F2F4F1', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={style.bigTitle}>Medications/Strategies</Typography>
                {medicationStrategies.map((item) => (
                    <Box key={item.id} sx={{ marginTop: '16px', padding: "10px", borderRadius: "8px" }}>
                        <Typography sx={{ fontSize: {sm:"26px",xs:"20px"}, fontWeight: "700", color: "#586554" }}>
                            {item.title}
                        </Typography>
                        {item.descriptions.map((description, idx) => (
                            <Box key={idx} display="flex" alignItems="center" sx={{ marginTop: '8px' }}>
                                <ChevronRightIcon />
                                <Typography sx={style.smallTitle}>
                                    {description}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                ))}
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper sx={{ border: "2px dashed #F87171", padding: '20px', borderRadius: '8px', backgroundColor: '#FEF2F2', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={style.bigTitle}>
                    When to Call the 
                    <span style={{ color: 'red', fontWeight: "700" }}> Hospice </span>
                </Typography>
                {whenToCallHospice.map((item, idx) => (
                    <Box key={idx} display="flex" alignItems="center" sx={{ marginTop: '16px', padding: "10px", borderRadius: "8px" }}>
                        <ChevronRightIcon />
                        <Typography sx={style.smallTitle}>
                            {item}
                        </Typography>
                    </Box>
                ))}
            </Paper>
        </Grid>
    </Grid>
</Box>
        </Box>
        </>
    );
}

export default Diarrhea;
