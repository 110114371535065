Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "bx_block_contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.regexContactNumber= /^\d{10}$/
exports.headerTitle="Have some feedback for Partner for Care?"
exports.headerDes="Tell us your issues or suggestions for our website!"
exports.titleHowDoWeContactYou="How do we contact you?*"
exports.labelYourName="Your Name*"
exports.placeholderYourName="Typed value"
exports.errorYourName="Please enter your name"
exports.labelEmail="Email"
exports.placeholderEmail="Enter email"
exports.errorEmail="Please enter your email and/or phone number"
exports.labelPhoneNumber="Phone Number"
exports.placeholderPhoneNumber="Enter your phone number"
exports.errorPhoneNumber="Please enter your email and/or phone number"
exports.labelIssue="Issue*"
exports.placeholderIssue="Select issue"
exports.errorIssue="Please select the issue"
exports.labelDescribeIssue="Describe the issue*"
exports.placeholderDescribeIssue="Your message"
exports.errorDescribeIssue="Please describe your issue"
exports.textButtonSendMessage="Send message"
exports.footerFirstSentenceText="Not familiar with a term? Click here to find it in our dictionary."
exports.footerSecondSentenceText=""
exports.errorEmailNotValid = "Email not valid";
exports.errorPhoneNotValid="Phone not valid"
// Customizable Area End