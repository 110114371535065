import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Link } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import SymptomOverviewSection from "./SymptomOverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import ItemWithAnnotation from "./ItemWithAnnotation.web";
import ItemWithLetter from "./ItemWithLetter.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";

const Span = styled('span')({
  color: '#DC2626',
});

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const SectionSubHeadingContainer = styled(LightSectionTitle)({
  fontSize: '20px', 
  fontWeight: '400',
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  fontSize: '22px', 
  fontWeight: '400',
});

const Sleep = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const pTags = doc.querySelectorAll('p');
    if (pTags && Array.from(pTags).some(p => p.nextElementSibling && p.nextElementSibling.tagName === 'UL')) {
      return (
        <ItemWithAnnotation
          withOrdering={doc.querySelector('p:has(strong)')?.previousElementSibling && doc.querySelector('p:has(strong)')?.previousElementSibling?.tagName === 'P' || false}
          order={doc.querySelector('p:has(strong)')?.previousElementSibling?.textContent || ''}
          orderStyles={{ backgroundColor: 'transparent', padding: '0', width: '62px', height: '62px' }}
          main={doc.querySelector('p:has(strong)')?.textContent || ''}
          annotationList={Array.from(doc.querySelectorAll('li')).map(li => <PlainTextItem>{ReactHtmlParser(li.innerHTML)}</PlainTextItem>)}
          containerStyles={{ flex: 1, alignItems: 'start' }} 
        />
      )
    }
    const itemWithChildrenList = Array.from(doc.querySelectorAll('li:has(div)')).map(li => {
      return {
        letter: li.children[0] && li.children[0]?.tagName === 'DIV' ? li.children[0]?.textContent : '',
        content: li.children[1] && li.children[1]?.tagName === 'P' ? li.children[1].innerHTML : '',
      }
    })
    if (itemWithChildrenList.length > 0) {
      const subHeading = doc.querySelector('p');
      return (
        <>
          {subHeading && subHeading?.nextElementSibling && subHeading?.nextElementSibling?.tagName === 'HR' 
            && <SectionSubHeadingContainer>{ReactHtmlParser(subHeading?.outerHTML)}</SectionSubHeadingContainer>}
          {itemWithChildrenList.map((item, index) => <ItemWithLetter key={index} {...item} content={ReactHtmlParser(item.content)} onNavigate={onNavigate} />)}
        </>
      )
    }
    return Array.from(doc.querySelectorAll('li')).map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
  }

  return (
    <Box pb={2.5}>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Sleep' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: '10px', backgroundColor: '#F2F4F1', borderRadius: '8px', paddingBottom: '20px' }}>
        <SymptomOverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && <SectionTitleContainer>{ReactHtmlParser(title?.title)}</SectionTitleContainer>} 
          shortDesc={title && <LightSectionSubtitle>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
        />
        {sectionList.map((section, index) => 
          <CustomSectionContainer
            key={index}
            withHeading
            heading={<LightSectionTitle>{ReactHtmlParser(section.name)}</LightSectionTitle>}
            containerStyle={extractTextNodeContent(section.name) !== 'Common Causes' ? { paddingTop: '20px', paddingBottom: '20px' } : 
              { paddingTop: '10px', paddingBottom: '40px' }}
            contentStyle={{ 
              marginTop: extractTextNodeContent(section.name) === 'What to discuss with Hospice' ? '4px' : '20px', 
              gap: extractTextNodeContent(section.name) === 'Medications'|| extractTextNodeContent(section.name) === 'What to discuss with Hospice' ? '40px' : '20px'
            }}
          >
            {section.children && section.children.length > 0 ? 
              <Grid container spacing={2.5}> 
                {section?.children && section?.children.map((child, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    {child.images.length > 0 ? 
                      <ItemWithImage image={child.images[0].url} text={ReactHtmlParser(child.definition)} /> 
                        : <Box py={2.5} sx={{ backgroundColor: '#FFF' }}>{renderList(child.definition)}</Box>}
                  </Grid>
                ))}                                                                                                          
              </Grid> : renderList(section.definition)
            }
          </CustomSectionContainer>)}
      </SectionListLayout>
    </Box>
  );
}

export default Sleep;
