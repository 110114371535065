import { Typography, Box, Grid, Divider } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const medicine1 = require('../../blocks/contentmanagement/assets/instructionIcon1.png');
const medicine2 = require('../../blocks/contentmanagement/assets/instructionIcon2.png');
const image1 = require('../../blocks/contentmanagement/assets/firstImg.png');
const image2 = require('../../blocks/contentmanagement/assets/secondImg.png');
const image3 = require('../../blocks/contentmanagement/assets/thirdImg.png');

const Span = styled('span')({
  color: 'red',
});

type DataItem = {
  title?: string;
  image: string;
  description: string;
};

type Section = {
  para: string;
  items: DataItem[];
};

const descriptions = [
  'They decrease pain and are useful for mild to moderate pain. They are not effective for severe pain.',
  'Less pain means being able to be more active, sleep better, and have a higher quality of life.',
  'These medications usually do not cause drowsiness like the Opioids.',
  'They can be taken with Opioids.',
];

const list: Section[] = [
  {
    para: 'Common Side effects',
    items: [
      { title: 'Tylenol-', image: image1, description: 'Very rare as long as stay within dose recommendations' },
      { title: 'NSAIDs-', image: image2, description: 'Upset stomach, kidney injury, increased risk of bleeding' },
      { title: 'Gabapentin-', image: image3, description: 'Drowsiness, constipation' },
    ],
  },
  {
    para: 'Serious Side effects - ',
    items: [
      { image: image1, description: 'Nausea and vomiting' },
      { image: image2, description: 'Uncontrolled pain' },
      { image: image3, description: 'Painful stomach cramps or bleeding' },
    ],
  },
];

const data = [
  {
    heading: 'How to take',
    image: medicine1,
    define: ['Take it by mouth', 'All medications should be taken with at least 1-2 ounces of fluid'],
  },
  {
    heading: 'When will it work',
    image: medicine2,
    define: [
      'It can start working in about 30 minutes',
      'It is important to take these medications if there is pain, they should not be held because the pain is not so bad',
    ],
  },
  {
    heading: 'Acetaminophen (Tylenol):',
    define: [
      'Do not take more than 2gms per day (That is 4 extra strength 500mg tabs)',
      'Remember that some Opioid preparations include Acetaminophen and this needs to be included in total daily dose',
    ],
  },
  {
    heading: 'NSAIDs:',
    define: [
      'Should be taken with food or after a meal',
      'If have history of kidney problems or history of bleeding or ulcers, discuss with Hospice Team if safe to take',
      'Usual dosing of Ibuprofen (Advil) is 400-600 mg three times per day, Naprosyn (Aleve) is 250 - 500mg twice a day',
    ],
  },
  {
    heading: 'Gabapentin (Neurontin):',
    define: [
      'Most useful for nerve pain (Neuropathic pain)',
      'Dose range varies quite a bit, from 100-300 mg, 1-3 times per day. Discuss with Hospice Team',
      'Often taken along with other pain medications',
    ],
  },
];

function Non_opioid_pain() {
  return (
    <>
      <Box
        sx={{
          padding: { xs: '20px 20px', md: '40px 130px' },
          background: '#F2F4F1',
          borderRadius: '8px',
          marginTop: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: 2,
            maxWidth: '702px',
            maxHeight: '99px',
            margin: '0 auto',
          }}
        >
          <Typography sx={{ fontSize: '26px', fontWeight: '700' }}>
            Understanding <Span>NON-OPIOID PAIN</Span> Medications
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2, fontWeight: '400', fontSize: '22px', color: '#586554' }}>
            Common medications: Acetaminophen (Tylenol), NSAIDs (Ibuprofen/Advil/Aleve/Naprosyn), Gabapentin (Neurontin)
          </Typography>
        </Box>
        <Typography
          sx={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center', fontSize: '26px', fontWeight: '700' }}
        >
          How will this medication help
        </Typography>
        <Box sx={{ textAlign: 'left', width: '100%' }}>
          {descriptions.map((description, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <ChevronRightIcon />
              <Typography variant="body1" sx={{ color: '#343C32', fontSize: '22px' }}>
                {description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#343C32',
          padding: { xs: '20px 20px', md: '40px 130px' },
          color: '#FFFFFF',
          borderRadius: '8px',
          marginTop: '20px',
        }}
      >
        {data.map((item, index) => (
          <Box key={index} sx={{ marginBottom: 5, maxWidth: '1140px', width: '100%', marginTop: '20px' }}>
            <Typography sx={{ marginBottom: 3, fontSize: '26px', fontWeight: '700' }}>{item.heading}</Typography>
            {item.image && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={2}>
                  <img src={item.image} alt={item.heading} style={{width:"100%",maxWidth:"120px"}} />
                </Grid>
                <Grid item xs={12} md={10}>
                  {item.define.map((define, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, textAlign: 'left' }}>
                      <ChevronRightIcon />
                      <Typography variant="body1" sx={{ marginLeft: 1 }}>
                        {define}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            )}
            {!item.image && (
              <Box>
                {item.define.map((define, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, textAlign: 'left' }}>
                    <ChevronRightIcon />
                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                      {define}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            {index === 0 && <Divider sx={{ margin: '40px 0', backgroundColor: '#586554' }} />}
          </Box>
        ))}
      </Box>
      <Box sx={{ padding: { xs: '20px 20px', md: '40px 130px' }, marginTop: '20px' }}>
        <Grid container spacing={2} sx={{ borderRadius: '8px' }}>
          <Grid item xs={12} md={6} sx={{ backgroundColor: '#F2F4F1', padding: '20px', borderRadius: '8px' }}>
            <Typography
              sx={{ marginBottom: 3, textAlign: 'center', fontSize: '26px', fontWeight: '700', color: '#586554' }}
            >
              {list[0].para}
            </Typography>
            {list[0].items.map((list, index) => (
              <Grid
                container
                key={index}
                spacing={2}
                alignItems="center"
                sx={{
                  marginBottom: 3,
                  width: '100%',
                  background: 'white',
                  borderRadius: '8px',
                  padding: '10px',
                  maxWidth: '100%',
                }}
              >
                <Grid item xs={3}>
                  <img src={list.image} alt="head" style={{ width: '100%', maxWidth: '80px', maxHeight: '80px' }} />
                </Grid>
                <Grid item xs={9} sx={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
                  {list.title && (
                    <Typography variant="body1" sx={{ fontWeight: 'bold',color:"#343C32" }}>
                      {list.title}
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ marginLeft: 1 ,color:"#343C32"}}>
                      {list.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={6} sx={{ backgroundColor: '#F7E2E2', padding: '20px', borderRadius: '8px', border: '2px dashed #F87171', marginTop: { xs: '20px', md: 0 } }}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 3,
                textAlign: 'center',
                fontSize: '26px',
                fontWeight: '700',
                color: '#586554',
              }}
            >
              {list[1].para}
              <Span>Call Hospice</Span>
            </Typography>
            {list[1].items.map((item, index) => (
              <Grid
                container
                key={index}
                spacing={2}
                alignItems="center"
                sx={{
                  marginBottom: 3,
                  width: '100%',
                  background: 'white',
                  borderRadius: '8px',
                  padding: '10px',
                  maxWidth: '100%',
                }}
              >
                <Grid item xs={3}>
                  <img src={item.image} alt={item.description} style={{ maxWidth: '80px', maxHeight: '80px' }} />
                </Grid>
                <Grid item xs={9}>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ marginLeft: 1,color:"#343C32" }}>
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Non_opioid_pain;
