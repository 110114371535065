import React, { CSSProperties, ReactNode } from 'react';
import { Box, Avatar, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '20px',
})

const Letter = styled(Avatar)({
  backgroundColor: '#D6DCD5', 
  borderRadius: '8px', 
  padding: '10px', 
  color: '#586554', 
  fontFamily: 'Lato', 
  fontSize: '36px', 
  fontWeight: '700'
})

const ContentContainer = styled(Box)({
  padding: '0 20px', 
  display: 'flex', 
  flexDirection: 'column', 
})

const NoticeContainer = styled(Box)({
  backgroundColor: '#F7E2E2',
  padding: '4px 20px', 
  borderRadius: '8px',
  border: '2px dashed #F87171',
  cursor: 'pointer'
})

const NormalText = styled(Typography)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32'
});

const NormalLink = styled(Link)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  textDecorationColor: '#343C32'
});

type IItemWithLetterProps = {
  letter: string | null;
  content: string | string[] | ReactNode;
  refUrl?: { text: string; link: string }
  onNavigate?: (to: string) => void;
  containerStyles?: CSSProperties;
  orderContainerStyles?: CSSProperties;
}

const ItemWithLetter = (props: IItemWithLetterProps) => (
  <Container sx={{ ...props.containerStyles }}>
    <Letter sx={{ ...props.orderContainerStyles }}>{props.letter}</Letter>
    <ContentContainer sx={{ gap: props.refUrl ? 0 : 2.5 }}>
      {typeof props.content === 'string' ? <NormalText>{props.content}</NormalText> : 
        <Box mb={props.refUrl ? 2.5 : 0} sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
          {Array.isArray(props.content) ? props.content.some(each => React.isValidElement(each)) ? 
            <NormalText>{props.content}</NormalText> : props.content.map((line, index) => <NormalText key={index}>{line}</NormalText>) : undefined}
        </Box>
      }
      {props.refUrl && 
        <NoticeContainer onClick={() => props.onNavigate && props.onNavigate(props.refUrl?.link || '')}>
          <NormalLink>{props.refUrl?.text}</NormalLink>
        </NoticeContainer>}
    </ContentContainer>
  </Container>
);

export default ItemWithLetter;
