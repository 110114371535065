Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.footerFirstSentenceText = "Not familiar with a term?";
exports.footerSecondSentenceText = " Click here to find it in our dictionary.";
exports.defaultHospiceName = "Kindred Healthcare";
exports.defaultHospiceNumber = "123-456-7890";
exports.defaultPatientName = "Grandpa Bob";
exports.defaultCaregiverName = "Celina Duncan";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "bx_block_catalogue/catalogues";
// Customizable Area End