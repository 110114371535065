import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box';


export default function UnauthorizedHeader() {
  return (
    <Container>
      <ImageContainer>
        <img style={webStyles.image} src={require('./group_logo.png')} />
      </ImageContainer>
      <ImageMobile>
        <img style={webStyles.imageMobile} src={require('./logo_mobile.png')} />
      </ImageMobile>
    </Container>
  );
}
const ImageMobile = styled(Box)({
  marginTop: 12,
  marginBottom: 12,
  marginLeft: 16,
  width: 265,
  height: 58,
  display: 'none',
  '@media (max-width: 600px)': {
    display: 'flex'
  }
});
const Container = styled(Box)({
  background: '#7C8E76',
    width: '100%',
    alignSelf: 'start',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 118,
  '@media (max-width: 600px)': {
    height: 80
  }
});
const ImageContainer = styled(Box)({
  marginTop: 12,
  marginBottom: 12,
  marginLeft: 130,
  width: 197,
  height: 84,
  display: 'flex',
  '@media (max-width: 600px)': {
    display: 'none'
  }
});
const webStyles = {
  image: {
    width: 197,
    height: 84,
  },
  imageMobile: {
    width: 265,
    height: 58,
  },
}