import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@builder/component-library';

interface Props {
  open: boolean,
  handleCloseModal: () => any,
  headerTitle: string,
  renderContent: () => any,
  textButtonLeft: string,
  textButtonRight: string,
  handleClickButtonLeft?: () => void
  handleClickButtonRight: () => void
  loading: boolean,
  height?: number
}
export default function CustomModal(props: Props) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{ ...webStyles.modalContainer, flexDirection: 'column', borderBottomRightRadius: 32 }}>
        <Typography style={webStyles.headerTitle}>{props.headerTitle}</Typography>
        <div style={{ ...webStyles.modalContent, flexDirection: 'column', }}>
          {props.renderContent()}
        </div>
        <div style={{ ...webStyles.buttonModalContainer, flexDirection: 'row' }}>
          <Button
            data-test-id={"cancel-send-email-button"}
            text={props.textButtonLeft}
            textStyle={{ fontFamily: "Lato", color: "#586554", }}
            onPress={props.handleClickButtonLeft ??  props.handleCloseModal}
            style={{ backgroundColor: '#fff', marginRight: 10, borderColor: "#D6DCD5", borderWidth: 1, borderStyle: 'solid', minWidth: 120 }}
          />
          <Button
            data-test-id={"send-email-button"}
            text={props.textButtonRight}
            onPress={props.handleClickButtonRight}
            loading={props.loading}
            textStyle={{ fontFamily: "Lato", color: "#fff" }}
            style={{ backgroundColor: '#7C8E76', minWidth: 120 }}
          />

        </div>
      </div>
    </Modal>
  );
}
const webStyles = {
  modalContainer: {
    background: "#fff",
    display: "flex",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: 8,
  },
  buttonModalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    paddingTop: 30,
    paddingRight: 16,
    paddingBottom: 30
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: 24,
    fontWeight: '700',
    marginTop: "24px",
    marginBottom: "24px",
    marginLeft: "40px",
    color: "#000"
  },
  modalContent: {
    border: '1px solid #D6DCD5',
    padding: "40px",
    display: 'flex',
  },
  modalTextContent: {
    fontSize: 16,
    fontFamily: "Lato",
    color: "#000"
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    borderRadius: 4,
    padding: 20,
    background: '#FFFFFF'
  },
}