import React from 'react';
import { Typography, Box, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const InlineFlexContainer = styled(Box)({
  display: "inline-flex",
  alignItems: "center"
});

const MainContainer = styled(Box)({
  marginTop: "10px", 
  marginBottom: "10px",
  minWidth: '512px', 
  display: "flex", 
  flexWrap: "wrap",
  alignItems: 'center',
  gap: "10px", 
});

const BreadcrumbElement = styled(Button)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
  textTransform: 'none'
});

const PreviousLevelText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
  fontSize: 22,
});

const LocationText = styled(PreviousLevelText)({
  color: "#343C32",
  fontWeight: "700",
});

const Seperator = styled(ChevronRightIcon)({
  color: "#96A591",
})

const BackIcon = styled(ArrowBackIcon)({
  color: "#343C32",
})

type IContentBreadcrumbsProps = {
  stacks: string;
  onNavigate: (to: string) => void;
}

const ContentBreadcrumbs = ({ stacks, onNavigate }: IContentBreadcrumbsProps) => (
  <MainContainer>
    <IconButton onClick={() => onNavigate('')}>
      <BackIcon />
    </IconButton>
    {stacks !== '' && stacks.split('/').map((stack, index) => (
      <InlineFlexContainer key={index} sx={{ gap: '2px' }}>
        {index < (stacks.split('/').length - 1) ? 
          <BreadcrumbElement onClick={() => onNavigate(stack)}>
            <PreviousLevelText>{stack}</PreviousLevelText>
          </BreadcrumbElement> : 
            <LocationText>
              {!stack.includes('-') ? stack : stack.split('-').join('/')}
            </LocationText>}
        {index < (stacks.split('/').length - 1) && <Seperator />}
      </InlineFlexContainer>
    ))}
  </MainContainer>
);

export default ContentBreadcrumbs;
