import React, { CSSProperties, ReactNode } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled(Box)({
  borderRadius: "8px", 
});

const List = styled(Box)({
  textAlign: 'left', 
  width: '100%', 
  display: 'flex', 
  flexDirection: 'column',
});

const FlexCol = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})


type ICustomSectionContainerProps = | {
  children: ReactNode;
  withHeading?: false;
  containerStyle?: CSSProperties; 
  contentStyle?: CSSProperties;
  variant?: 'dark' | 'light' | 'alert';
  removeVerticalPadding?: boolean;
  shrink?: boolean;
  innerContainerStyle?: CSSProperties; 
} | {
  children: ReactNode;
  withHeading: true;
  heading: ReactNode;
  containerStyle?: CSSProperties; 
  contentStyle?: CSSProperties;
  variant?: 'dark' | 'light' | 'alert';
  shrink?: boolean;
  innerContainerStyle?: CSSProperties; 
}

export const CustomSectionContainer = (props: ICustomSectionContainerProps) => (
  <MainContainer px={{ xs: "30px", sm: "60px", md: "130px" }} py={5} sx={{ 
    backgroundColor: props.variant ? props.variant === 'dark' ? '#343C32' : 'transparent' : '#F2F4F1',      
    ...props.containerStyle 
  }}>
    {props.shrink ? 
      <Box 
        bgcolor={!props.variant ? "#F2F4F1" : props.variant === 'dark' ? '#343C32' : '#FEF2F2'} 
        p={2.5} borderRadius={2} 
        sx={{ border: (props.variant && props.variant === 'alert') ? '2px dashed #F87171' : '', ...props.innerContainerStyle }}
      >
        {props.withHeading && props.heading}
        <List sx={{ 
          marginTop: props.withHeading ? 2.5 : 0, gap: 2.5,
          color: '#343C32',
          ...props.contentStyle 
        }}>
          {props.children}
        </List>
      </Box> : <>
        {props.withHeading && props.heading}
        <List sx={{ 
          marginTop: props.withHeading ? 2.5 : 0, gap: 2.5,
          color: props.variant && props.variant === 'dark' ? '#FFFFFF' : '#343C32',
          ...props.contentStyle 
        }}>
          {props.children}
        </List>
      </>}
  </MainContainer>
);

type ISubcatContainerProps = | {
  children: ReactNode;
  withHeading?: false;
  subHeading?: ReactNode;
  containerStyle?: CSSProperties; 
} | {
  children: ReactNode;
  withHeading: true;
  heading: ReactNode;
  subHeading?: ReactNode;
  containerStyle?: CSSProperties; 
}

export const SubcatContainer = (props: ISubcatContainerProps) => (
  <List sx={{ flex: 1, my: 2.5, gap: 2.5, ...props.containerStyle }}>
    {props.withHeading && props.heading}
    {props.subHeading}
    {props.children}
  </List>
);

export const SectionListLayout = ({ children, styles }: { children: ReactNode, styles?: CSSProperties }) => (
  <FlexCol sx={{ gap: 2.5, ...styles }}>
    {children}
  </FlexCol>
);