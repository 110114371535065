export const contactIcon = require("../assets/contact_icon.png");
export const basicCareImg = require("../assets/basic_care.png");
export const dementiaImg = require("../assets/dementia.png");
export const dyingImg = require("../assets/dying.png");
export const firstWeekImg = require("../assets/first_week.png");
export const medicationsImg = require("../assets/medication.png");
export const nurseImg = require("../assets/nurse.png");
export const symptomsImg = require("../assets/symptoms.png");
export const categoryNavigateButtonIcon = require("../assets/category_navigate_button.png");
export const categoryNavigateButtonIconHover = require("../assets/category_navigate_button_hover.png");
export const catalogueItems = [
    {
      text: "Medications", 
      path: "medications",
      img: medicationsImg
    },
    {
      text: "Symptoms", 
      path: "symptoms",
      img: symptomsImg
    },
    {
      text: "When to call the Nurse", 
      path: "nurse",
      img: nurseImg
    },
    {
      text: "The first week in Hospice ", 
      path: "first_week",
      img: firstWeekImg
    },
    {
      text: "What to expect when dying", 
      path: "dying",
      img: dyingImg
    },
    {
      text: "Basic care", 
      path: "basic_care",
      img: basicCareImg
    },
    {
      text: "Dementia", 
      path: "dementia",
      img: dementiaImg
    },
  ]