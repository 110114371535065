import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const toiletManImage = require('./toilet_man.svg');
const howToTakeImage = require('./how_to_take.svg');
const whenItWorkImage = require('./when_they_work.svg');

interface MedicationLaxativesProps {
  title: string,
}

export default function MedicationLaxatives(props: MedicationLaxativesProps) {
  const renderArrowRight = (color: string) => {
    return <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.997666 19.0147C1.48767 19.5047 2.27767 19.5047 2.76767 19.0147L11.0777 10.7047C11.4677 10.3147 11.4677 9.68469 11.0777 9.29469L2.76767 0.984688C2.27767 0.494687 1.48767 0.494687 0.997666 0.984688C0.507666 1.47469 0.507666 2.26469 0.997666 2.75469L8.23767 10.0047L0.987666 17.2547C0.507666 17.7347 0.507666 18.5347 0.997666 19.0147Z" fill={color}/>
    </svg>
    
  }
  const navbarStyles = {
    dash: {
      color: "#96A591",
      fontStyle: "Lato"
    },
  };
  const {
    title,
  } = props;
  return (
    <>
    <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "10px", marginBottom: "10px",gap:"10px" , alignItems: 'center'}}>
    <ArrowBackIcon />
    <NavigationText sx={navbarStyles.dash}>Dashboard</NavigationText>
    <ChevronRightIcon sx={navbarStyles.dash} />
    <NavigationText sx={navbarStyles.dash}>Medications</NavigationText>
    <ChevronRightIcon sx={navbarStyles.dash} />
    <NavigationText style={{fontWeight: "700", color: "#343C32"}}>Laxatives</NavigationText>
  </Box>
   <div style={{display: 'flex', flexDirection: 'column', rowGap: 20}}>
    <div style={{display: 'flex', flexDirection: 'column', background: '#F2F4F1',padding: 40, borderRadius: 8,  paddingLeft: 200, paddingRight: 200,}}>
      <LatoText style={{fontWeight: "700", fontSize: 26, textAlign: 'center'}}>
      <span style={{color: "#343C32"}}>Understanding </span>
      <span style={{color: "#DC2626"}}>LAXATIVES</span>
      </LatoText>
      <LatoText
      style={{ fontSize: 22, color: "#586554", marginTop: 16, textAlign: 'center'}}
      >Common medications: Miralax, Senna, Docusate, Dulcolax</LatoText>
      <LatoText style={{fontWeight: "700", fontSize: 26, color: "#586554", marginTop: 40, textAlign: 'center'}}>{title}</LatoText>
      <div style={{display: "flex", flexDirection: "row",columnGap: 35, marginTop: 32}}>
        <img src={toiletManImage} style={{width: 120, height: 120}}/>
        <div style={{display: "flex", flexDirection: "column", rowGap: 10}}>
          <RowItemTop>
          {renderArrowRight("#343C32")}
          <DescriptionText>Constipation is common with serious illnesses</DescriptionText>
          </RowItemTop>
          <RowItemTop>
          {renderArrowRight("#343C32")}
          <DescriptionText>It is very important that if pain medications (Opioids) are being taken that a laxative is used every day.</DescriptionText>
          </RowItemTop>
          <LatoText style={{fontWeight: "700", fontSize: 26, marginTop: 10}}>Aggravated by :</LatoText>
          <RowItemTop>
          {renderArrowRight("#343C32")}
          <DescriptionText>Pain medications</DescriptionText>
          </RowItemTop>
          <RowItemTop>
          {renderArrowRight("#343C32")}
          <DescriptionText>Being in bed & less active</DescriptionText>
          </RowItemTop>
          <RowItemTop>
          {renderArrowRight("#343C32")}
          <DescriptionText>Change in diet</DescriptionText>
          </RowItemTop>
        </div>
      </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', background: '#343C32', padding: 40, paddingLeft: 200, paddingRight: 200, borderRadius: 8, rowGap: 40}}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#fff", }}>How it works</LatoText>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'left', columnGap: 35, marginTop: 32, alignItems: 'center' }}>
            <img src={howToTakeImage} style={{ width: 120, height: 120 }} />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 10, justifyContent: 'center' }}>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Take it by mouth</DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>For over-the-counter medications, follow the package instructions or as directed by Hospice Team</DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Most of these medications are over-the-counter and easy to use</DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Most are gentle and increase the amount of fluid in the colon to help passage of stool</DescriptionText>
          </RowItemTop>
            </div>
          </div>
        </div>
        <div style={{background: "#586554", height: 1}}/>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#fff", }}>When will it work</LatoText>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'left', width: 1000, columnGap: 35, marginTop: 32, alignItems: 'center'  }}>
            <img src={whenItWorkImage} style={{ width: 120, height: 120 }} />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 10, justifyContent: 'center' }}>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Can work within 30 minutes, but may take up to an hour</DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>If no bowel movement in 2 hours, repeat dose</DescriptionText>
          </RowItemTop>
            </div>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', columnGap: 20}}>
      <div style={{display: 'flex',flex: 1, flexDirection: 'column', background: "#F2F4F1", padding: 40, paddingLeft: 80, paddingRight: 80, gap: 20, borderRadius: 8}}>
        <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#586554", textAlign: "center"}}>Common side effects</LatoText>
        <RowItem>
        {renderArrowRight("#343C32")}
        <DescriptionText style={{ color: "#343C32" }}>Very few with these medications</DescriptionText>
        </RowItem>
        <RowItem>
        {renderArrowRight("#343C32")}
        <DescriptionText style={{ color: "#343C32" }}>May cause cramping pain</DescriptionText>
        </RowItem>
        <RowItem>
        {renderArrowRight("#343C32")}
        <DescriptionText style={{ color: "#343C32" }}>May cause too many bowel movements or diarrhea</DescriptionText>
        </RowItem>
        </div>
        <div style={{display: 'flex',flex: 1, flexDirection: 'column', background: "#FEF2F2", padding: 40, paddingLeft: 20, paddingRight: 20, gap: 20, borderRadius: 8, border: "2px dashed #F87171"}}>
        <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#586554", textAlign: "center"}}>Serious side effects - <span style={{color: "#DC2626"}}>Call Hospice</span></LatoText>
        <RowItem>
        {renderArrowRight("#343C32")}
        <DescriptionText style={{ color: "#343C32" }}>Moderate or severe abdominal pain</DescriptionText>
        </RowItem>
        <RowItem>
        {renderArrowRight("#343C32")}
        <DescriptionText style={{ color: "#343C32" }}>No bowel movement after second dose</DescriptionText>
        </RowItem>
        <RowItem>
        {renderArrowRight("#343C32")}
        <DescriptionText style={{ color: "#343C32" }}>Rectal bleeding seen in toilet bowl</DescriptionText>
        </RowItem>
        </div>
      </div>
   </div>
   </>
  );
}
const RowItemTop = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: 10
});
const RowItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
  marginTop: 10,
  marginBottom: 10,
  alignItems: "center",
  columnGap: 20
});
const LatoText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400"
});
const DescriptionText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400",
  fontSize: 22,
});
const NavigationText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
  fontSize: 22,
});


