import React from "react";

// Customizable Area Start
import { Input, Button } from '@builder/component-library';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
export const configJSON = require("./config");
import { Formik } from "formik";

import * as Yup from "yup";

import UnauthorizedHeader from "../../../components/src/UnauthorizedHeader";
import UnauthorizedFooter from "../../../components/src/UnauthorizedFooter";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    password: Yup.string().trim()
    .matches(configJSON.passwordExp, configJSON.errorPassword)
    .required(configJSON.errorPassword),
    confirmPassword: Yup.string().trim()
    .matches(configJSON.passwordExp, configJSON.errorPassword)
    .required(configJSON.errorPassword).oneOf([Yup.ref('password'), null], configJSON.errorConfirmPassword),
  });
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <UnauthorizedHeader />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
          <Box sx={styles.formContainerStyle}>
            <Typography style={{ ...styles.mainLabelText, textAlign: 'center', marginTop: 20, fontFamily: "Lato" }}>{configJSON.titleResetYourPassword}</Typography>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={this.validationSchema}
              validateOnMount={true}
              onSubmit={(values, actions) => {
                this.goToConfirmationAfterPasswordChange(values);
              }}
              data-test-id="new-password-form"
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                setFieldTouched,
                touched,
                isValid
              }) => (
                <FormContainer>
                  <div
                    style={{ marginTop: 27}}
                  >
                    <Typography style={styles.textLabelSub}>{configJSON.labelNewPassword}</Typography>
                    <Input
                      data-test-id={"txtInputPassword"}
                      keyboardType={configJSON.firstInputKeyboardStyle}
                      placeholder={configJSON.placeholderNewPassword}
                      secureTextEntry={!this.state.isShowPassword}
                      onChangeText={handleChange('password')}
                      errorStyle={{ color: '#DC2626', fontSize: 12 }}
                      onBlur={() => setFieldTouched("password")}
                      error={(errors.password && touched.password) ? errors.password : undefined}
                      rightIcon={
                        <Button
                          data-test-id="btnTogglePassword"
                          aria-label="toggle password visibility"
                          onPress={this.changeShowPassword}
                          style={[styles.removeBackground]}
                          icon={this.state.isShowPassword ? (
                            <VisibilityOffOutlinedIcon style={{width: 24, height: 24, color: '#C3CBC0'}}/>
                          ) : (
                            <VisibilityOutlinedIcon style={{width: 24, height: 24, color: '#C3CBC0'}}/>
                          )}
                        />
                      }
                    />
                  </div>
                  <div
                    style={{ marginTop: 27}}
                  >
                    <Typography style={styles.textLabelSub}>{configJSON.labelConfirmPassword}</Typography>
                    <Input
                      data-test-id={"txtInputConfirmPassword"}
                      keyboardType={configJSON.firstInputKeyboardStyle}
                      placeholder={configJSON.placeholderConfirmPassword}
                      secureTextEntry={!this.state.isShowConfirmPassword}
                      onChangeText={handleChange('confirmPassword')}
                      errorStyle={{ color: '#DC2626', fontSize: 12 }}
                      onBlur={() => setFieldTouched("confirmPassword")}
                      error={(errors.confirmPassword && touched.confirmPassword) ? errors.confirmPassword : undefined}
                      rightIcon={
                        <Button
                          data-test-id="btnToggleConfirmPassword"
                          aria-label="toggle confirm password visibility"
                          onPress={this.changeShowConfirmPassword}
                          style={[styles.removeBackground]}
                          icon={this.state.isShowConfirmPassword ? (
                            <VisibilityOffOutlinedIcon style={{width: 24, height: 24, color: '#C3CBC0'}}/>
                          ) : (
                            <VisibilityOutlinedIcon style={{width: 24, height: 24, color: '#C3CBC0'}}/>
                          )}
                        />
                      }
                    />
                  </div>
                  <Box style={{ paddingTop: 30, paddingBottom: 20 }}>
                    <Button
                      data-test-id={"btnResetPassword"}
                      loading={this.state.loading}
                      text={configJSON.btnTxtResetPassword}
                      textStyle={{ fontFamily: "Lato", color: isValid ? "#fff" : '#7C8E76' }}
                      onPress={() => {
                        if(isValid){
                          handleSubmit()
                        }
                        }}
                      style={{ backgroundColor: isValid ? '#7C8E76' : '#D6DCD5' }}
                    />
                  </Box>
                </FormContainer>
              )}
            </Formik>
          </Box>
          <ImageContainer>
            <img style={{ ...styles.image, objectFit: 'cover', }} src={require('../assets/loginImage.png')} />
          </ImageContainer>
        </div>
        <UnauthorizedFooter goToPP={this.goToPrivacyPolicy} goToTAC={this.goToTermsAndCondition}/>
      </div>
      // Customizable Area End
    );
  }

}

  // Customizable Area Start
  const styles = {
    formContainerStyle: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      height: "100%",
      flexDirection: 'column',
    },
    mainLabelText: {
      fontSize: 24,
      fontWeight: "700",
      letterSpacing: -0.12,
      color: "#343C32"
    },
    errorStyle: {
      color: "#DC2626",
    },
    checkBoxText: {
      fontSize: 16,
      color: "#343C32",
    },
    removeBackground: {
      backgroundColor: "transparent" 
    },
    buttonsWrapper: {
      flex: 1,
    },
    textLabelSub: {
      fontSize: 14,
      fontWeight: '700',
      color: '#343C32',
      fontFamily: "Lato"
    },
    image: {
      width: '100%',
      borderBottomLeftRadius: 45,
      aspectRatio: 1
    },
   
  }
  const ImageContainer = styled(Box)({
    display: 'flex',
    flex: 1,
    '@media (max-width: 1180px)' : {
    display: 'none'
    }
  });
  const FormContainer = styled(Box)({
    boxSizing: 'border-box',
    display: 'flex',
    alignSelf: 'center',
    width: 360,
    paddingLeft: "16px",
    flexDirection: 'column',
  paddingRight: "16px",
    "@media (max-width: 600px)": {
      width: "100%"
    },
  });
  // Customizable Area End