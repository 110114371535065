import React, { useState } from 'react';
import { Box, Typography, FormControlLabel, FormLabel, Radio, RadioGroup, Container, Grid, Divider } from '@mui/material';
import { borderRadius } from 'react-select/src/theme';
const pain = require('../../blocks/contentmanagement/assets/pain.png')
export default function AbbeyPainScale() {
  const styles = {
    bigTitle: {
      fontSize: "26px",
      color: "#343C32",
      fontWeight: "700"
    },
    smallTitle: {
      fontSize: "24px",
      color: "#343C32",
      fontWeight: '400'
    },
    radioHeader: {
      fontWeight: '700',
      textAlign: 'center'
    },
    questionText: {
      fontWeight: '600',
      marginBottom: '5px'
    },
    questionDescription: {
      fontSize: '14px',
      color: 'textSecondary'
    },
    divider: {
      margin: '10px 0',
      backgroundColor: '#7C8E76'
    },
    table:{
      maxWidth:"431px",
      height:"100%",
      maxHeight: "120px",
      width:"100%",
      display:"flex",
      justifyContent:"Center",
      alignItems:"center",
      gap:"14px",
      padding:"26px 0px 26px 0px"
    },
    radio:{
      width:"100%",
      maxWidth:'182px',
      height:"100%",
      textAlign:"center"

    },
    total:{
     marginTop:"32px",
      textAlign:"end", 
  border: "2px dashed #7C8E76",
  borderRadius: "8px",
  padding: "20px"
    }
  };

  const questions = [
    {
      id: 1,
      text: 'Vocalization',
      description: 'Severe may include: whispering, groaning, crying',
    },
    {
      id: 2,
      text: 'Facial Expression',
      description: 'Severe may include: Looking tense, frowning, grimacing, looking frightened',
    },
    {
      id: 3,
      text: 'Change in Body Language',
      description: 'Severe may include: Frightened, rocking, guarding part of body, withdrawn',
    },
    {
      id: 4,
      text: 'Behavioral Change',
      description: 'Severe may include: Increased confusion, refusing to eat, alteration in usual patterns',
    },
    {
      id: 5,
      text: 'Physiological Change',
      description: 'Severe may include: Temperature, pulse or blood pressure outside normal limits, perspiring, flushing or pallor',
    },
    {
      id: 6,
      text: 'Physical Changes',
      description: 'Severe may include: Skin tears, pressure area, arthritis, contractures, previous injuries',
    }
  ];

  const [values, setValues] = useState(Array(questions.length).fill(''));

  const handleChange = (index:any, value:any) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
  };

  const calculateTotalScore = () => {
    const scores = values.map(value => {
      switch (value) {
        case 'absent': return 0;
        case 'mild': return 1;
        case 'moderate': return 2;
        case 'severe': return 3;
        default: return 0;
      }
    });
    const total = scores.reduce((acc:any, score) => acc + score, 0);
    let severity = 'No Pain';
    if (total >= 7) severity = 'Severe';
    else if (total >= 4) severity = 'Moderate';
    else if (total >= 1) severity = 'Mild';
    return { total, severity };
  };

  const { total, severity } = calculateTotalScore();

  return (
    <Container>
      <Box sx={{ marginTop: '10px' }}>
        <Typography sx={styles.bigTitle}>
          Abbey Pain Scale for Patients with Dementia
        </Typography>
        <Typography sx={styles.smallTitle}>
          This quiz measures the severity of pain in people with Dementia who cannot verbalize.
        </Typography>
        <Typography sx={{ color: "#343C32", fontSize: "26px" }}>
          <span style={{ fontWeight: '700', fontSize: "26px" }}>How to use:</span> While observing, click a score for each question 1 through 6.
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={3} sm={2}>
          <Box sx={styles.radio}><Typography sx={styles.radioHeader}>Absent</Typography></Box>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Box sx={styles.radio}><Typography sx={styles.radioHeader}>Mild</Typography></Box> 
        </Grid>
        <Grid item xs={3} sm={2}>
          <Box sx={styles.radio}><Typography sx={styles.radioHeader}>Moderate</Typography></Box>  
        </Grid>
        <Grid item xs={3} sm={2}>
          <Box sx={styles.radio}>
          <Typography sx={styles.radioHeader}>Severe</Typography>
          </Box>      
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />

      {questions.map((question, index) => (
        <React.Fragment key={question.id}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} >
              <Box sx={styles.table}>
              <Box><Typography sx={styles.questionText}>{`Q${question.id}`}</Typography></Box>
              <Box><Typography sx={styles.questionText}>{question.text}</Typography>
              <Typography sx={styles.questionDescription}>{question.description}</Typography></Box>
              </Box>
           
            </Grid>
            <Grid item xs={12} sm={9}>
              <RadioGroup
                row
                name={`question${question.id}`}
                value={values[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                sx={{ flexWrap: 'nowrap', justifyContent: 'space-around' }}
              >
                <Box sx={styles.radio}><FormControlLabel value="absent" control={<Radio />} label="" /></Box>
                <Box sx={styles.radio}><FormControlLabel value="mild" control={<Radio />} label="" /></Box>
                <Box sx={styles.radio}><FormControlLabel value="moderate" control={<Radio />} label="" /></Box>
                <Box sx={styles.radio}><FormControlLabel value="severe" control={<Radio />} label="" /></Box>
              </RadioGroup>
             
            </Grid>
          </Grid>
          <Divider sx={styles.divider} />
        </React.Fragment>
      ))}

      <Box mt={4} sx={styles.total}>
        <Typography variant="h6">
          Total pain score: <span style={{color:"#FFB127",fontWeight:"800"}}>{total}-{severity}</span>
        </Typography>
      </Box>
      <Box mt={4}>
          <img style={{maxWidth:"1160px",width:"100%"}} src={pain} alt="" />
      </Box>
    </Container>
  );
}
