import React, { CSSProperties, ReactNode } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)({
  display: 'flex', 
  alignItems: 'center', 
  gap: '10px'
})

const NormalText = styled(Typography)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
});

const PlainTextItem = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
  <Container sx={{ ...style }}>
    <ChevronRightIcon />
    <NormalText variant="body1">{children}</NormalText>              
  </Container>
);

export default PlainTextItem;