import React from "react";
// Customizable Area Start
import {  Button } from '@builder/component-library';
import { Formik } from "formik";
import ModalConfirm from "../../../components/src/ModalConfirm";
import * as Yup from "yup";
import HospiceHeader from "../../../components/src/HospiceHeader";
import Loader from "../../../components/src/Loader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  Box,
  Input as MaterialInput,
  Typography as MaterialTypography,
  TextareaAutosize,
  styled,
  IconButton,
  Button as MaterialButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// Customizable Area End

import DeleteAccountController, {
  Props,
  configJSON,
} from "./DeleteAccountController";

export default class DeleteAccount extends DeleteAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    password: Yup.string().trim()
    .matches(this.regexMatKhau, configJSON.errorMessagePasswordMatch)
    .required(configJSON.errorMessagePasswordMatch),
    confirmPassword: Yup.string().trim()
    .matches(this.regexMatKhau, configJSON.errorMessagePasswordMatch)
    .required(configJSON.errorMessagePasswordMatch).oneOf([Yup.ref('password'), null], 'Password does not match'),
    comment: Yup.string().trim().required(configJSON.errorMessageComment)
  });
  renderHeader = () => {
    return   <HospiceHeader
    data-test-id={"authorized-header"}
    navigation={this.props.navigation}
  />
  }
  renderLoader = () => {
    return (
      <Loader loading={false}/>
    )
  }
  renderFormData = () => {
    return (
      <Formik
        data-test-id="information-form"
        initialValues={this.initialDeleteAccountValues}
        onSubmit={(value) => this.handleSubmitDeleteAccount(value.password, value.comment)}
        validationSchema={this.validationSchema}
        innerRef={this.formikDeleteAccountRef}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          values,
          setFieldValue,
          isValid,
          touched,
          dirty
        }) => (
          <SecondFormStepContainer>
            <MainHeader>{configJSON.headerDeleteAccount}</MainHeader>
            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelPassword}*
                </InputLabel>
                <FormInput
                  onChange={handleChange("password")}
                  onBlur={() => setFieldTouched("password")}
                  className={this.getErrorClassName(errors.password, touched.password)}
                  id="password"
                  data-test-id="password-field"
                  placeholder={configJSON.placeholderPassword}
                  disableUnderline
                  type={values.isShowPassword ? "text" : "password"}
                  endAdornment={
                    <PasswordButton data-test-id="password-button" onClick={() => setFieldValue("isShowPassword", !values.isShowPassword)}>
                      {values.isShowPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </PasswordButton>
                  }
                />
                {this.isErrorField(errors.password, touched.password) && (
                  <ValidationParagraph data-test-id="hospice-name-error-message" className="error">
                    {errors.password}
                  </ValidationParagraph>
                )}
              </InputContainer>

              <InputContainer>
                <InputLabel>
                Confirm Password*
                </InputLabel>
                <FormInput
                 onChange={handleChange("confirmPassword")}
                  onBlur={() => setFieldTouched("confirmPassword")}
                  className={this.getErrorClassName(errors.confirmPassword, touched.confirmPassword)}
                  id="confirmPassword"
                  data-test-id="confirm-password-field"
                  placeholder={configJSON.placeholderConfirmPassword}
                  disableUnderline
                  type={values.isShowConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <PasswordButton data-test-id="confirm-password-button" onClick={() => setFieldValue("isShowConfirmPassword", !values.isShowConfirmPassword)}>
                      {values.isShowConfirmPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </PasswordButton>
                  }
                />
                {this.isErrorField(errors.confirmPassword, touched.confirmPassword) && (
                  <ValidationParagraph className="error">
                    {errors.confirmPassword}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>

            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelComment}*
                </InputLabel>
                <FormTextArea
                  style={{ resize: "none" }}
                  minRows={4}
                  onChange={handleChange("comment")}
                  onBlur={() => setFieldTouched("comment")}
                  className={this.getErrorClassName(errors.comment, touched.comment)}
                  id="comment"
                  data-test-id="comment-field"
                  placeholder={configJSON.placeholderComment}
                />
                {this.isErrorField(errors.comment, touched.comment) && (
                  <ValidationParagraph className="error">
                    {errors.comment}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
              {dirty && <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 10, paddingBottom: 30}}>
              <Button
                      data-test-id={"btn-register"}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      style={{backgroundColor: "#fff", borderWidth: 1, borderColor: "#D6DCD5", width: 120}}
                      text={configJSON.btnTxtCancel}
                      onPress={this.handleGoBack}
                    />
              <Button
                      data-test-id={"button-delete-account"}
                      textStyle={{fontSize: 16, fontWeight: '700', color: isValid ? '#fff' : "#7C8E76", fontFamily: "Lato"}}
                      style={{ width: 152, marginLeft: 12, backgroundColor: isValid ? "#7C8E76" : "#D6DCD5"}}
                      text={configJSON.buttonDeleteAccount}
                      onPress={handleSubmit}
                    />
              </div>}
          </SecondFormStepContainer>
        )}
      </Formik>)
  }
  renderModalConfirm = () => {
    return <ModalConfirm
    data-test-id="modal-confirm-delete"
    open={this.state.isShowModalConfirmDelete}
    handleCloseModal={this.handleCloseModalConfirmDelete}
    headerTitle={configJSON.headerModalTitle}
    renderFirstDes={() => <MaterialTypography style={{fontFamily: "Lato"}}>{configJSON.modalFirstLine}</MaterialTypography>}
    secondLineDes={configJSON.modalSecondLine}
    textButtonLeft="Keep Account"
    textButtonRight="Delete Account"
    loading={this.state.loading}
    height={368}
    handleClickButtonRight={this.handleDeleteAccount}
    />
  }
  renderBreadcrumb = () => {
   return (
    <div style={{display: 'flex', flexDirection: "row", columnGap: 10, alignItems: 'center', paddingLeft: 10, paddingTop: 4, paddingBottom: 4, borderBottom: '1px solid #F2F4F1'}}>
      <IconButton data-test-id="go-back-button" onClick={this.handleGoBack}><ArrowBackIcon style={{color: "#343C32"}}/></IconButton>
      <ButtonBreadcrumb 
      data-test-id="go-home-button" 
      onClick={this.handleGoToDashboard}
      >
      <LatoText 
      style={{color: "#475569", textTransform: "capitalize"}}
      >
        Home
        </LatoText>
      </ButtonBreadcrumb>
      <KeyboardArrowRightIcon
       style={{color: "#C3CBC0"}}/>
      <ButtonBreadcrumb
       data-test-id="go-setting-button" 
       onClick={this.handleGoToSettings}
       >
      <LatoText
       style={{textTransform: "capitalize"}}
       >
        Settings
        </LatoText>
      </ButtonBreadcrumb>
      <KeyboardArrowRightIcon 
      style={{color: "#C3CBC0"}}/>
      <LatoText
       style={{fontWeight: '700'}}
       >Delete Account</LatoText>
    </div> 
   )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding: 20}}>
        {this.renderLoader()}
        {this.renderModalConfirm()}
        {this.renderHeader()}
        {this.renderBreadcrumb()}
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 100, paddingRight: 100, paddingTop: 20}}>
        {this.renderFormData()}
      </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const SecondFormStepContainer = styled(Box)({
  boxSizing: 'border-box',
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "& button.active": {
    backgroundColor: "#7C8E76",
    color: "#FFFFFF",
  },
  "@media (max-width: 414px)": {
    width: "100%"
  },
});
const FormRow = styled(Box)({
  display: "flex",
  gap: "16px",
  "@media (max-width: 414px)": {
    flexDirection: "column",
  },
});

const InputGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const ButtonBreadcrumb = styled(MaterialButton)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});
const MainHeader = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: 24,
  letterSpacing: 0,
  color: "#343c32ff",
});
const InputContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "4px",
  "& .error": {
    color: "#DC2626",
    borderColor: "#F87171",
  },
  "& ::placeholder": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C3CBC0",
    textTransform: "none",
  },
  "& .logo-placeholder": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C3CBC0",
    textTransform: "none",
  },
});
const PasswordButton = styled(IconButton)({
  width: "21.65px",
  height: "18.95px",
  "& svg": {
    fill: "#C3CBC0",
  },
});

const InputLabel = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: 0,
  color: "#343c32ff",
});
const FormTextArea = styled(TextareaAutosize)({
  width: "100%",
  paddingLeft: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
  fontFamily: "Lato",
  fontSize: 16
});
const FormInput = styled(MaterialInput)({
  width: "100%",
  height: "44px",
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "#C3CBC0",
  borderStyle: "solid",
  fontFamily: "Lato",
  '& .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Default placeholder color
  },
  '&.error .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Placeholder color when error class is present
  },
});

const ValidationParagraph = styled(MaterialTypography)({
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  width: 0,
  minWidth: "100%",
  color: "#7C8E76"
});
const LatoText = styled(MaterialTypography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#0F172A"
})
// Customizable Area End
