import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const stripHtmlTags = (str: string): string => str.replace(/<\/?[^>]+(>|$)/g, "");
const Container = styled(Box)({
  color: '#FFFFFF',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
});

const InfoSection = styled(Box)({
  maxWidth: '1140px',
  width: '100%',
});

const InfoContainer = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '35px',
});

const InfoDetailList = styled(Box)({
  textAlign: 'left',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const InfoDetailLayout = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const ThemeText = styled(Typography)({
  fontFamily: 'Lato',
});

const HeadingText = styled(ThemeText)({
  fontSize: '26px',
  fontWeight: '700',
});

const BodyText = styled(ThemeText)({
  fontSize: '22px',
  fontWeight: '400',
});

interface IDescription {
  heading: string;
  images?: { url: string; type: string }[];
  desc: string[];
  withDivider?: boolean;
}

const TreatmentDescSection = ({ treatment }: { treatment: IDescription[] }) => (
  <Container
    mt={2.5}
    bgcolor="#343C32"
    sx={{ p: { xs: '20px 20px', md: '40px 130px' } }}
  >
    {treatment?.map((detail, index) => (
      <InfoSection key={index}>
        <HeadingText>{stripHtmlTags(detail?.heading)}</HeadingText>
        <InfoContainer mt={detail?.images?.length ? 4 : 2.5}>
          {detail.images && (
            <Box>
              {detail?.images.map((img, idx) => (
                <img key={idx} src={img.url} alt={`Image ${idx}`} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} />
              ))}
            </Box>
          )}
          <InfoDetailList sx={{ gap: detail?.images?.length ? 1.5 : 2 }}>
            {detail?.desc.map((line, index) => (
              <InfoDetailLayout key={index}>
                <ChevronRightIcon />
                <BodyText variant="body1">{stripHtmlTags(line)}</BodyText>              
              </InfoDetailLayout>
            ))}
          </InfoDetailList>
        </InfoContainer>
        {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
      </InfoSection>
    ))}
  </Container>
);

export default TreatmentDescSection;
