import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  Box,
  Input as InputMaterial,
  Typography as TypographyMaterial,
  styled,
  MenuItem
} from "@mui/material";
import { imgAddUser, imgTrash, imgLogout } from "./assets";
import { Formik, FormikErrors} from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ModalConfirm from "../../../components/src/ModalConfirm";
import Loader from "../../../components/src/Loader";
import Toast from "../../../components/src/Toast";
import HospiceHeader from "../../../components/src/HospiceHeader";
// Customizable Area End

import CfpingeneratorController, {
  Props,
  configJSON,
  ICaregiver
} from "./CfpingeneratorController";

export default class Cfpingenerator extends CfpingeneratorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    patientName: Yup.string().trim().required('Enter a valid patient name'),
    caregivers: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().trim().required('Enter a valid caregiver name'),
        phone: Yup.string()
          .matches(/^\d{10}$/, 'Enter a valid phone number')
          .required('Phone number is required'),
        email: Yup.string()
          .email('Enter a valid email')
          .required('Email is required')
      })
    )
  });
 
  checkName = (name: string) => {
    if(name !== null && name.trim() !== ""){
      return null
    }
    return "Enter a valid caregiver name"
  }
  checkPhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    if (phone !== null && phoneRegex.test(phone)) {
      return null;
    }
    return "Enter a valid phone number";
  };
  checkEmail = (email: string) => {
    const emailRegex = /^[^\s@]{1,30}@[^\s@]{1,15}\.[^\s@]{2,4}$/;
    if (email !== null && emailRegex.test(email)) {
      return null;
    }
    return "Enter a valid email";
  };
  getErrorClassName = (error: string | null | undefined, touched: boolean | undefined) => {
    return error && touched ? "error" : ""
  }
  isErrorField = (error: string | null | undefined, touched: boolean | undefined) => {
    return error && touched
  }
  renderModalDelete= () => {
    return <ModalConfirm
    data-test-id="modal-confirm-delete"
    open={this.state.isOpenModalDelete}
    handleCloseModal={this.handleCloseModalDelete}
    headerTitle={configJSON.headerModalTitle}
    renderFirstDes={() => <Typography style={styles.modalTextContent}>{configJSON.modalFirstLine}{" "}<span style={{...styles.modalTextContent, fontWeight: '700'}}>{this.state.caregiverSelected?.name}</span>?</Typography>}
    secondLineDes={configJSON.modalSecondLine}
    textButtonLeft={configJSON.modalButtonLeft}
    textButtonRight={configJSON.modalButtonRight}
    loading={this.state.loading}
    handleClickButtonRight={this.handleDeleteAccount}
    />
  }
  renderModalLogout= () => {
    return <ModalConfirm
    data-test-id="modal-confirm-logout"
    height={288}
    open={this.state.isOpenModalConfirm}
    handleCloseModal={this.handleCloseModalLogout}
    headerTitle={configJSON.titleHeaderModalConfirm}
    renderFirstDes={() => <Typography style={styles.modalTextContent}>{configJSON.modalConfirmFirstLine}{" "}<span style={{...styles.modalTextContent, fontWeight: '700'}}>{this.state.caregiverSelected?.name}</span>?</Typography>}
    textButtonLeft={configJSON.modalButtonLeft}
    textButtonRight={configJSON.modalConfirmButtonRight}
    loading={this.state.loading}
    handleClickButtonRight={this.handleLogoutCaregiver}
    />
  }
  renderLoader = () => {
    return (
      <Loader loading={this.state.fetching}/>
    )
  }
  renderToast = () => {
    return (
      <Toast data-test-id="popup-message" textMessage={this.state.successMessage} isShow={this.state.isShowToast} width={401}/>
    )
  }
  renderHeader = () => {
    return   <HospiceHeader
    navigation={this.props.navigation}
    data-test-id={"authorized-header"}
  />
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <div style={{display: "flex", flexDirection: 'column', background: '#fff', padding: 20}}>
        {this.renderHeader()}
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 120, paddingRight: 120}}>
        {this.renderModalDelete()}
        {this.renderModalLogout()}
        {this.renderLoader()}
        {this.renderToast()}
        <Formik
          initialValues={ this.state.currentPatientData ?? { patientName: "", statusOfPatient: "Active", caregivers: [{caregiver_id: null, name: "", phone: "", email: "", relation: ""}]}}
          validationSchema={this.validationSchema}
          enableReinitialize
          innerRef={this.formikRef}
          data-test-id="form-data"
          onSubmit={(values, actions) => {
            this.handleSubmitForm(values.patientName, values.statusOfPatient, values.caregivers)
          }}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            setFieldTouched,
            touched,
            isValid,
            values
          }) => (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column',  marginTop: 24 }}>
           {values.caregivers.length < 2 && <div style={{...styles.warnTitleContainer, flexDirection: "row",}}>
          <img style={{width: 28, height: 28}} src={imgAddUser} />
          <Typography data-test-id="text-caregiver" style={styles.textHeader}><span style={{fontWeight: '700'}}>{configJSON.boldTextHeader}</span>{configJSON.textHeader}</Typography>
        </div>}
              <div style={styles.headerFormContainer}>
                <Typography style={{ ...styles.textFormHeader, fontWeight: "700" }}>{configJSON.patientInformation}</Typography>
              </div>
              <div style={{ ...styles.horizontalContainer, flexDirection: "row" }}>
                <FormRow>
                  <InputContainer>
                    <InputLabel >
                      {configJSON.labelPatientName}
                    </InputLabel>
                    <FormInput
                      onChange={handleChange("patientName")}
                      onBlur={() => setFieldTouched("patientName")}
                      className={this.getErrorClassName(errors.patientName, touched.patientName)}
                      id="patientName"
                      value={values.patientName}
                      data-test-id="patient-name-field"
                      placeholder={configJSON.placeholderPatientName}
                      disableUnderline
                    />
                    {this.isErrorField(errors.patientName, touched.patientName) && (
                      <ValidationParagraph data-test-id="patient-name-error" className="error">
                        {errors.patientName}
                      </ValidationParagraph>
                    )}
                  </InputContainer>

                  <InputContainer>
                    <InputLabel >
                      {configJSON.selectStatusOfPatient}
                    </InputLabel>
                    <StatusSelect
                      onChange={(event: SelectChangeEvent<any>) => {
                        setFieldValue("statusOfPatient", event.target.value)
                      }}
                      value={values.statusOfPatient}
                      id="status-of-patient"
                      data-test-id="status-field"
                      displayEmpty
                      IconComponent={KeyboardArrowDownRoundedIcon}
                    >
                      {["Active", "Expired"].map((state: string) => (
                        <MenuItem value={state} key={state}>{state}</MenuItem>
                      ))}
                    </StatusSelect>
                    {errors.statusOfPatient && (
                      <ValidationParagraph data-test-id="status-error" className="error">
                        {errors.statusOfPatient}
                      </ValidationParagraph>
                    )}
                  </InputContainer>
                </FormRow>
              </div>
              <div style={styles.headerFormContainer}>
                <Typography style={{ ...styles.textFormHeader, fontWeight: "700" }}>{configJSON.caregiverInformation}</Typography>
              </div>
              <div>
                {values.caregivers.map((caregiver, index) => {
                  const nameError = this.checkName(caregiver.name)
                  const phoneError = this.checkPhone(caregiver.phone)
                  const emailError = this.checkEmail(caregiver.email)
                  const errorCaregiver = errors.caregivers?.[index] as FormikErrors<ICaregiver>
                  const touchedCaregiver = touched.caregivers?.[index]
                  const onPressedDeleteButton = () => {
                    this.handlePressedDeleteButton(caregiver,values.caregivers.length,  () => {
                        const newCaregivers = [...values.caregivers]
                        newCaregivers.splice(index, 1)
                        setFieldValue('caregivers',newCaregivers)
                    })
                  }
                  return <div data-test-id="caregiver-row" style={{paddingTop: 40}} key={index.toString()}>
                    <div style={{display: 'flex', width: "100%", flexDirection: 'row', justifyContent: "space-between"}}>
                    <Typography style={{fontFamily: "Lato",fontSize: 18, fontWeight: "700", color: "#7C8E76" }}>Caregiver {(index + 1).toString()}</Typography>
                    <div style={{display: "flex", flexDirection: "row"}}>
                    {caregiver.caregiver_id && <Button
                      data-test-id="button-logout-caregiver"
                      style={{...styles.removeBackground, padding: 6, paddingLeft: 5, paddingRight: 5}}
                      textStyle={{fontSize: 14, fontWeight: '700', color: '#DC2626', fontFamily: "Lato"}}
                      iconPosition="left"
                      icon={<img src={imgLogout} style={{width: 24, height: 24}}/>}
                      text={configJSON.textLogout}
                      onPress={() => {
                        this.handleOpenModalLogoutCaregiver(caregiver)
                      }}
                    />}
                    <Button
                      data-test-id={`button-delete-caregiver-${index}`}
                      style={{...styles.removeBackground, opacity: this.getOpacity(values.caregivers.length),  padding: 6, paddingLeft: 5, paddingRight: 5}}
                      textStyle={{fontSize: 14, fontWeight: '700', color: '#DC2626', fontFamily: "Lato"}}
                      iconPosition="left"
                      icon={<img src={imgTrash} style={{width: 24, height: 24}}/>}
                      text={configJSON.btnTxtDelete}
                      onPress={onPressedDeleteButton}
                    />
                     </div>
                      </div>
                 
                  <FormRow>
                    <InputContainer>
                      <InputLabel >
                        {configJSON.labelCaregiverName}
                      </InputLabel>
                      <FormInput
                        onChange={handleChange(`caregivers.${index}.name`)}
                        onBlur={() => setFieldTouched(`caregivers.${index}.name`)}
                        id={`caregivers.${index}.name`}
                        className={this.getErrorClassName(nameError, touchedCaregiver?.name)}
                        value={caregiver.name}
                        data-test-id={`caregivers-${index}-name-field`}
                        placeholder={configJSON.placeholderCaregiverName}
                        disableUnderline
                      />
                      {this.isErrorField(nameError, touchedCaregiver?.name) && (
                      <ValidationParagraph data-test-id={`caregivers-${index}-name-error`} className="error">
                        {nameError}
                      </ValidationParagraph>
                    )}
                    </InputContainer>
                    <InputContainer>
                      <InputLabel >
                        {configJSON.labelRelationToPatient}
                      </InputLabel>
                      <FormInput
                        onChange={handleChange(`caregivers.${index}.relation`)}
                        onBlur={() => setFieldTouched(`caregivers.${index}.relation`)}
                        value={caregiver.relation}
                        id={`caregivers.${index}.relation`}
                        data-test-id={`caregivers-${index}-relation-field`}
                        placeholder={configJSON.placeholderRelationToPatient}
                        disableUnderline
                      />
                    </InputContainer>
                  </FormRow>
                  <FormRow>
                    <InputContainer>
                      <InputLabel >
                        {configJSON.labelPhone}
                      </InputLabel>
                      <FormInput
                        onChange={
                          handleChange(`caregivers.${index}.phone`)
                        }
                        onBlur={() => setFieldTouched(`caregivers.${index}.phone`)}
                        id={`caregivers.${index}.phone`}
                        name={`caregivers.${index}.phone`}
                        value={caregiver.phone}
                        className={this.getErrorClassName(phoneError, touchedCaregiver?.phone)}
                        data-test-id={`caregivers-${index}-phone-field`}
                        placeholder={configJSON.placeholderPhone}
                        disableUnderline
                      />
                      {this.isErrorField(errorCaregiver?.phone, touchedCaregiver?.phone) && (
                      <ValidationParagraph data-test-id={`caregivers-${index}-phone-error`} className="error">
                        { errorCaregiver?.phone}
                      </ValidationParagraph>
                    )}
                    </InputContainer>
                    <InputContainer>
                      <InputLabel >
                        {configJSON.labelEmail}
                      </InputLabel>
                      <FormInput
                        onChange={handleChange(`caregivers.${index}.email`)}
                        onBlur={() => setFieldTouched(`caregivers.${index}.email`)}
                        id={`caregivers.${index}.email`}
                        className={this.getErrorClassName(emailError, touchedCaregiver?.email)}
                        data-test-id={`caregivers-${index}-email-field`}
                        value={caregiver.email}
                        placeholder={configJSON.placeholderEmail}
                        disableUnderline
                      />
                      {this.isErrorField(errorCaregiver?.email, touchedCaregiver?.email) && (
                      <ValidationParagraph data-test-id={`caregivers-${index}-email-error`} className="error">
                        {errorCaregiver?.email}
                      </ValidationParagraph>
                    )}
                    </InputContainer>
                  </FormRow>
                </div>
                })}
                <div style={{display: 'flex', width: '100%',alignSelf: 'flex-start', marginTop: 10, paddingBottom: 30}}>
                {values.caregivers.length < 3 ? <Button
                      data-test-id={"add-caregiver-button"}
                      style={{...styles.removeBackground}}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      text={configJSON.btnTxtAddCaregiver}
                      onPress={() => {
                        setFieldValue('caregivers', [...values.caregivers, {name: "", email: "", relation: "", phone: ""}])
                      }}
                    /> :
                    <Typography style={{fontFamily: "Lato", fontSize: 16, color: "#7C8E76", fontStyle: 'italic'}}>{configJSON.textMaxCaregiver}</Typography>}
                </div>
                
              </div>
              <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 10, paddingBottom: 30}}>
              <Button
                      data-test-id={"btn-register"}
                      style={{backgroundColor: "#fff", borderWidth: 1, borderColor: "#D6DCD5", width: 120}}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      text={configJSON.btnTxtCancel}
                      onPress={() => {this.goToDashboard()}}
                    />
              <Button
                      data-test-id={"button-submit"}
                      style={{backgroundColor: isValid ? "#7C8E76" : "#D6DCD5", width: 120, marginLeft: 12}}
                      textStyle={{fontSize: 16, fontWeight: '700', color: isValid ? '#fff' : "#7C8E76", fontFamily: "Lato"}}
                      text={configJSON.btnTxtSave}
                      loading={this.state.loading}
                      onPress={() => {handleSubmit()}}
                    />
              </div>
            </div>
          )}
        </Formik>
      </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
  const styles = {
    horizontalContainer: {
      display: "flex",
    
    },
    headerFormContainer: {
      padding: 10,
      borderRadius: 8,
      background: "#F2F4F1",
      marginTop: 20
      
    },
    modalTextContent: {
      fontSize: 16,
      fontFamily: "Lato",
         color: "#000"
    },
    textFormHeader: {
      fontSize: 16,
      color: "#343C32",
      fontFamily: "Lato"
    },
    warnTitleContainer: {
      display: "flex",
      padding: 16,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 4,
      alignItems: "center",
      backgroundColor: "#FEF3C7"
    },
    fontBold: {
      fontWeight: "700"
    },
    removeBackground: {
      backgroundColor: "transparent" 
    },
    textHeader: {
      fontFamily: "Lato",
      fontSize: 16,
      color: "#343C32",
      marginLeft: 2
    }
  }
  const InputLabel = styled(TypographyMaterial)({
    fontFamily: "Lato",
    fontWeight: "700",
    fontSize: 14,
    color: "#343C32",
  });
  const ValidationParagraph = styled(TypographyMaterial)({
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    width: 0,
    minWidth: "100%",
  });
  const FormInput = styled(InputMaterial)({
    width: "100%",
    height: "44px",
    paddingLeft: "8px",
    paddingRight: "8px",
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#C3CBC0",
    borderStyle: "solid",
    fontFamily: "Lato",
  });
  const InputGroup = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  });
  const FormRow = styled(Box)({
  display: "flex",
  gap: "16px",
  width: "100%",
});
  const InputContainer = styled(Box)({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: 24,
    gap: "4px",
    "& .error": {
      color: "#DC2626",
      borderColor: "#F87171",
    },
    "& ::placeholder": {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 400,
      color: "#C3CBC0",
      textTransform: "none",
    },
    "& .error ::placeholder": {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 400,
      color: "#C3CBC0",
      textTransform: "none",
    },
    "& .logo-placeholder": {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 400,
      color: "#C3CBC0",
      textTransform: "none",
    },
  });
  const StatusSelect = styled(Select)({
    width: "100%",
    height: "44px",
    borderRadius: "8px",

    "& .MuiSelect-root": {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      paddingLeft: "8px",
      borderWidth: "1px",
      borderColor: "#C3CBC0",
      borderStyle: "solid",
    },
    "& .MuiSelect-select": {
      color: "#586554", 
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 500,
    }
  });
// Customizable Area End
