import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles'

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import SymptomOverviewSection from './SymptomOverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SubcatContainer } from './CustomContainer.web';
import { ISectionData } from './SymptomARD.web';
import ReactHtmlParser, { extractTextNodeContent } from './ReactHtmlParser';

import { IContentData, ITermDetail } from '../../blocks/contentmanagement/src/ContentManagementController';

const SectionListLayout = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
})

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  fontSize: '22px', 
  fontWeight: '400',
});

const NormalText = styled(Typography)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
});

const Span = styled('span')({
  color: '#DC2626'
});

export default function NauseaVomiting({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  const renderOverviewAnnotation = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item)}</PlainTextItem>))
  }
  const renderText = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.querySelectorAll('p')).map((p, index) =>  
      <NormalText key={index} textAlign="center" variant="body1">
        {Array.from(p.childNodes).map((node, nodeIndex) => (
          node.nodeType === Node.TEXT_NODE ? node.textContent : <Span key={nodeIndex}>{node.textContent}</Span>
        ))}
      </NormalText>);
  }

  return (
    <Box pb={2.5}>
      <ContentBreadcrumbs stacks='Dashboard/Medications/Nausea & Vomiting' onNavigate={onNavigate} />
      <SectionListLayout>
        <SymptomOverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && <SectionTitleContainer>{ReactHtmlParser(title?.title)}</SectionTitleContainer>} 
          shortDesc={title && <LightSectionSubtitle>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
          annotation={sectionList.length > 0 && extractTextNodeContent(sectionList[0].name) === 'Why they are needed' ? 
            <OverviewAnnotation 
              title={ReactHtmlParser(sectionList[0].name)} 
              image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
              content={renderOverviewAnnotation(sectionList[0].definition)}
              layoutStyle={{ alignItems: 'center' }}
            /> : undefined}
        />

        <CustomSectionContainer variant="dark">
          {sectionList
            .filter(section => extractTextNodeContent(section.name) === 'How to Take' || extractTextNodeContent(section.name) === 'When will they work')
            .map((detail, index) => (
            <Box key={index}>
              <OverviewAnnotation 
                title={ReactHtmlParser(detail.name)}
                image={detail.images.length > 0 ? detail.images[0]?.url : ''}
                content={renderOverviewAnnotation(detail.definition)}
              />
              {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
            </Box>
          ))}
        </CustomSectionContainer>

        <CustomSectionContainer contentStyle={{ flexDirection: 'row' }}>
          {sectionList
            .filter(section => extractTextNodeContent(section.name) !== 'Why they are needed' && 
              extractTextNodeContent(section.name) !== 'How to Take' && extractTextNodeContent(section.name) !== 'When will they work')
            .map((section, index) => 
              <SubcatContainer
                key={index}
                containerStyle={{ paddingLeft: '20px', paddingRight: '20px' }}
                withHeading
                heading={<LightSectionTitle>{ReactHtmlParser(section.name)}</LightSectionTitle>}
                subHeading={renderText(section.definition)}
              >
                {renderOverviewAnnotation(section.definition)}
              </SubcatContainer>)}
        </CustomSectionContainer>
      </SectionListLayout>
   </Box>
  );
}