import React from 'react'
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MaterialButton from '@mui/material/Button';
type ModalInformationProps = {
    isOpen: boolean,
    handleClose: () => void
}
function ModalInformation(props: ModalInformationProps) {
    return (
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="information-modal-title"
            aria-describedby="information-modal-description"
        >
            <ModalContainer>
                <ModalHeader>
                    Information
                </ModalHeader>
                <ModalContentContainer>
                    <ConditionsText>
                        If you are a hospice, kindly click on 'Hospice' and proceed with registration. If you are a caregiver, we encourage you to reach out to your hospice and request to be added as a caregiver.
                    </ConditionsText>
                </ModalContentContainer>
                <ModalControlsContainer>
                    <CloseButton onClick={props.handleClose}>Close</CloseButton>
                </ModalControlsContainer>
            </ModalContainer>
        </Modal>
    )
}
const ModalContainer = styled(Box)({
    background: "#fff",
    display: "flex",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flex: 1,
    flexDirection: "column",
    width: "600px",
    borderRadius: "8px",
    borderBottomRightRadius: "32px",
    overflow: "hidden",
    '@media (max-width: 600px)': {
        width: "343px"
    }
})
const ModalHeader = styled(Typography)({
    padding: "24px 40px 24px 40px",
    borderBottom: "1px solid #D6DCD5",
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#0F172A",
    '@media (max-width: 600px)': {
        fontSize: "20px",
        padding: "24px 16px 24px 16px",
    }
})

const ModalContentContainer = styled(Box)({
    flex: 1,
    padding: "40px",
    overflow: "auto",
    '@media (max-width: 600px)': {
        padding: "32px 16px 32px 16px",
    }
})
const ConditionsText = styled(Typography)({
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: '8px',
    color: "#343C32"
})

const ModalControlsContainer = styled(Box)({
    display: "flex",
    padding: "30px 16px 30px 0px",
    borderTop: "1px solid #D6DCD5",
    justifyContent: "end",
})

const CloseButton = styled(MaterialButton)({
    backgroundColor: "#7C8E76",
    padding: "10px 16px 10px 16px",
    width: "100px",
    borderRadius: "8px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFFFFF",
    textTransform: "capitalize",
    "&:hover": {
        cursor: "pointer",
        backgroundColor: "#7C8E76",
        color: "#FFFFFF",
    }
});
export default ModalInformation