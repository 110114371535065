import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; 
const breath1 = require('../../blocks/contentmanagement/assets/breath1.png')
const breath2 = require('../../blocks/contentmanagement/assets/breath2.png')
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  List,
  ListItemText,
  ListItem,
  Box,
  Typography,
  ListItemIcon,
} from "@mui/material";

const navbarStyles = {
  main: {
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  dash: {
    color: "#96A591",
    fontStyle: "Lato !important"
  },
};


const styles = {
  bigTitle: {
    fontSize: "26px",
    fontWeight: "700",
    color: "#586554",
    fontStyle:"Lato !important"

  },
  smallTitle: {
    fontSize: "22px",
    color: "#343C32",
    fontStyle:"Lato !important"
  },
};

const medicalReasons = [
  'COPD exacerbation',
  'Fluid build up from heart failure',
  'Pneumonia/infection',
  'Fever',
  'Worsening anemia'
];

const nonMedicalCauses = [
  'Untreated pain',
  'Anxiety',
  'Emotional/spiritual distress',
  'Oxygen tank- empty, not working, tubes kinked',
  'Room too hot',
  'Doing too much, lying flat instead of upright'
];

const data = [
  {
    image: require('../../blocks/contentmanagement/assets/commonthing1.png'),
    description: 'Make sure oxygen is connected and flowing'
  },
  {
    image: require('../../blocks/contentmanagement/assets/commonthing2.png'),
    description: 'Try pursed lip breathing  - Video Link'
  },
  {
    image: require('../../blocks/contentmanagement/assets/commonthing3.png'),
    description: 'Blow fan across face - this is very effective!!!'
  },
  {
    image: require('../../blocks/contentmanagement/assets/commonthing4.png'),
    description: 'Relaxation techniques - music, quiet place, meditation'
  },
  {
    image: require('../../blocks/contentmanagement/assets/commonthing5.png'),
    description: 'Talk with loved one, spiritual counselor, friend about how you are feeling'
  }
];

const medicalTreatment = [
  'Opioids are very effective at relieving shortness of breath. Your prescribed opioid medication can and should be used! Opioids can provide rapid relief. If pill form, within 30 min, liquid within 5-10 min',
  'If feeling anxious, use your prescribed anxiety medication - lorazepam (Ativan)',
  'Inhaler medications can be used if wheezing is present or if they have been used before for breathing problems'
];

const hospiceCallReasons = [
  'Shortness of breath is increasing despite above measures',
  'Oxygen tank is empty or not working',
  'Running low on medications - opioids, anxiety medications, inhalers',
  'Uncontrolled coughing'
];

function ShortnessOfBreath() {
  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "10px", gap: "10px" }}>
        <ArrowBackIcon />
        <Typography sx={navbarStyles.dash}>Dashboard</Typography>
        <ChevronRightIcon sx={navbarStyles.dash} />
        <Typography sx={navbarStyles.dash}>Symptoms</Typography>
        <ChevronRightIcon sx={navbarStyles.dash} />
        <Typography sx={{color:"#343C32",fontFamily:"Lato",fontWeight:"700"}}>Shortness of Breath</Typography>
      </Box>
      
      <Box sx={{ padding: { xs: "10px 20px", sm: "30px 60px", md: "40px 80px" }, backgroundColor: "#F2F4F1", borderRadius: "8px", marginTop: "10px" }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={2} container justifyContent="center">
            <img src={breath1} alt='image1' />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography sx={{ color: "#343C32", fontSize: "26px", textAlign: "center", fontWeight: "700",fontStyle:"Lato !important" }}>
              Shortness of <span style={{ color: "#DC2626",fontStyle:"Lato !important" }}>Breath</span>
            </Typography>
            <Box>
              <Typography sx={styles.smallTitle} style={{textAlign:"center"}}>
                There are both medical and 'non-medical' causes for feeling more short of breath
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} container justifyContent="center">
            <img src={breath2} alt='image2' />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ height: "100%", borderRadius: "8px", maxHeight: "347px", padding: { xs: "10px 20px", sm: "30px 60px", md: "40px 80px" }, backgroundColor: "#F2F4F1", marginTop: "10px" }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom sx={styles.bigTitle}>
                Common Medical Reasons
              </Typography>
              <List>
                {medicalReasons.map((reason, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText sx={styles.smallTitle} primary={reason} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom sx={styles.bigTitle}>
                Common Non-Medical Causes
              </Typography>
              <List>
                {nonMedicalCauses.map((cause, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText sx={styles.smallTitle} primary={cause} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ padding: { xs: "10px 20px", sm: "30px 60px", md: "40px 80px" }, backgroundColor: "#F2F4F1", marginTop: "10px", borderRadius: "8px" }}>
        <Container>
          <Typography align="center" gutterBottom sx={styles.bigTitle}>
            Common Things to Try
          </Typography>
          <Grid container spacing={4}>
            {data.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Card sx={{ display: "flex", flexWrap: "wrap", borderRadius: "8px", padding: "10px", alignItems: "center" }}>
                  <CardMedia
                    sx={{ maxWidth: "120px", maxHeight: "120px" }}
                    component="img"
                    height="150"
                    image={item.image}
                    alt={`Image ${index + 1}`}
                  />
                  <CardContent>
                    <Typography variant="body1" sx={styles.smallTitle}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box sx={{ padding: { xs: "10px 20px", sm: "30px 60px", md: "40px 80px" }, backgroundColor: "#F2F4F1", marginTop: "10px", borderRadius: "8px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography align="center" gutterBottom sx={styles.bigTitle}>
              Medical Treatment
            </Typography>
            <List sx={{ color: "#343C32" }}>
              {medicalTreatment.map((treatment, index) => (
                <ListItem sx={{ background: "#FFFFFF", borderRadius: "8px", padding: "20px", border: "1px solid #D6DCD5", marginBottom: "20px" }} key={index}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <Typography sx={styles.smallTitle}>{treatment}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" align="center" gutterBottom sx={styles.bigTitle}>
              When to Call the <span style={{ color: "#DC2626",fontStyle:"Lato !important" }}>Hospice</span>
            </Typography>
            <List>
              {hospiceCallReasons.map((reason, index) => (
                <ListItem sx={{ background: "#FFFFFF", borderRadius: "8px", padding: "20px", border: "1px solid #D6DCD5", marginBottom: "20px" }} key={index}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <Typography sx={styles.smallTitle}>{reason}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ShortnessOfBreath;
