import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)({
  marginTop: '20px',
  paddingBottom: '20px',
  textAlign: 'center',
});

const CommonContainer = styled(Grid)({
  height: '100%',
  backgroundColor: '#F2F4F1',
  padding: '40px 20px',
  borderRadius: '8px',
});

const SeriousContainer = styled(CommonContainer)({
  backgroundColor: '#F7E2E2',
  border: '2px dashed #F87171',
});

const EffectItemContainer = styled(Box)({
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const EffectItemLayout = styled(Grid)({
  width: '100%',
  maxWidth: '100%',
  padding: '10px',
  display: 'inline-flex',
  gap: '20px',
  alignItems: 'center',
  background: 'white',
  borderRadius: '8px',
});

const Image = styled('img')({
  width: '100%',
  maxWidth: '80px',
  maxHeight: '80px',
});

const ThemeText = styled(Typography)({
  fontFamily: 'Lato',
});

const ContentText = styled(ThemeText)({
  color: '#343C32',
  textAlign: 'left',
  fontSize: '22px',
  fontWeight: '400',
});

const StrongContentText = styled(ContentText)({
  fontWeight: 'bold',
});

const TitleText = styled(ThemeText)({
  fontSize: '26px',
  color: '#586554',
  fontWeight:700,
});

interface IEffectItem {
  prefix?: string;
  content: string;
  descImage?: string;
}

interface IEffectGroup {
  title: string;
  items: IEffectItem[];
}

type ISideEffectSectionProps = {
  sideEffects: {
    common: IEffectGroup;
    serious: IEffectGroup;
  };
};

const highlightHospice = (text: string) => {
  return text.replace(/(hospice)/gi, '<span style="color: red;">$1</span>');
};

const SideEffectSection = ({ sideEffects }: ISideEffectSectionProps) => (
  <Container sx={{ paddingX: { xs: '20px', md: '130px' } }}>
    <Grid container spacing={2.5}>
      <Grid item xs={12} md={6}>
        <CommonContainer>
          <TitleText
            dangerouslySetInnerHTML={{ __html: highlightHospice(sideEffects.common.title) }}
          />
          <EffectItemContainer>
            {sideEffects.common.items.map((item, index) => (
              <EffectItemLayout key={index}>
                {item?.descImage && (
                  <Image
                    src={item?.descImage}
                    alt={`Common side effects ${index}`}
                  />
                )}
                <StrongContentText variant="body1">
                  {item?.prefix && `${item?.prefix} - `}
                  <ContentText variant="body1" display="inline">
                    {item.content}
                  </ContentText>
                </StrongContentText>
              </EffectItemLayout>
            ))}
          </EffectItemContainer>
        </CommonContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <SeriousContainer>
          <TitleText
            dangerouslySetInnerHTML={{ __html: highlightHospice(sideEffects.serious.title) }}
          />
          <EffectItemContainer>
            {sideEffects.serious.items.map((item, index) => (
              <EffectItemLayout key={index}>
                {item?.descImage && (
                  <Image
                    src={item?.descImage}
                    alt={`Serious side effects ${index}`}
                  />
                )}
                <StrongContentText variant="body1">
                  {item?.prefix && `${item?.prefix} - `}
                  <ContentText variant="body1" display="inline">
                    {item.content}
                  </ContentText>
                </StrongContentText>
              </EffectItemLayout>
            ))}
          </EffectItemContainer>
        </SeriousContainer>
      </Grid>
    </Grid>
  </Container>
);

export default SideEffectSection;
