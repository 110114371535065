Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.listOfStates = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", 
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", 
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", 
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", 
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

exports.mapOfRegexes = {
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])(?=.*[A-Z])[A-Za-z\d@$!%*?&]{8,}$/,
  contactNumber: /^\d{10}$/
}

exports.labels = {
  email: "Email",
  passwordLabel: "Password",
  hospiceName: "Hospice Name*",
  contactNumber: "Contact Number for Patients to Reach You*",
  website: "Website*",
  license: "License",
  address: "Address*",
  city: "City*",
  selectedState: "State*",
  postalCode: "Zip Code*",
  hospiceLogo: "Hospice Logo"
}

exports.placeholders = {
  email: "Your email",
  passwordPlaceholder: "Your password",
  hospiceName: "Hospice Name",
  contactNumber: "Enter contact number",
  website: "Enter website URL",
  license: "Enter license",
  address: "Enter address",
  city: "Enter city",
  selectedState: "Select State",
  postalCode: "Enter zip code",
  hospiceLogo: "Upload your logo"
}

exports.validationErrors = {
  email: "Enter a valid email address",
  hospiceName: "Enter your hospice name",
  contactNumber: "Enter contact number",
  website: "Enter the URL to your website",
  address: "Enter valid address",
  city: "Enter valid city",
  selectedState: "Select state",
  postalCode: "Enter valid zip code",
  hospiceLogo: "One file allowed. Acceptable file types include .png, .jpg, .jpeg.",
  agreement: "You must agree to conditions"
}

exports.signUpHeader = "Register for Partner For Care";

exports.buttonsText = {
  firstStep: "Next",
  lastStep: "Register"
}

exports.agreementText = "By signing up for this platform, you are agreeing to our";
exports.andText = "and";
exports.termsAndConditionsLabel = "Terms and Conditions";
exports.privacyPolicyLabel = "Privacy Policy";

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypeEdit = "PATCH";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.labelOr = "Already have an account?";
exports.btnTxtLogin = "Login";
// Customizable Area End
