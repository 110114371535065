import React from "react";

// Customizable Area Start
import { Input, Checkbox, Button } from '@builder/component-library';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Formik} from 'formik';
import * as Yup from 'yup';

import { imgCheckbox } from "./assets";
export const configJSON = require("./config");

import UnauthorizedHeader from "../../../components/src/UnauthorizedHeader";
import UnauthorizedFooter from "../../../components/src/UnauthorizedFooter";
import ModalInformation from "../../../components/src/ModalInformation";
import Toast from "../../../components/src/Toast";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email address').required('Enter a valid email address'),
    password: Yup.string().trim().required('Enter a valid password'),
  });
  renderHospiceLogin = () => {
    return (
      <Formik
              initialValues={{ email: this.state.email, password: this.state.password }}
              validationSchema={this.validationSchema}
              validateOnMount={true}
              innerRef={this.formikRef}
              onSubmit={(values, actions) => {
                this.handleLogin(values.email, values.password)
                actions.setSubmitting(false);
              }}
              data-test-id="login-form"
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                setFieldTouched,
                touched,
                isValid
              }) => (
                <FormContainer>
                  <div
                  style={{marginTop: 27,}}
                  >
                    <Typography style={styles.textLabel}>{configJSON.titleEmail}</Typography>
                    <Input
                      data-test-id={"txtInputEmail"}
                      keyboardType={configJSON.firstInputKeyboardStyle}
                      // inputContainerStyle={}
                      placeholder={configJSON.placeHolderEmail}
                      onChangeText={handleChange("email")}
                      onBlur={() => setFieldTouched("email")}
                      error={(errors.email && touched.email) ? errors.email : undefined}
                      errorStyle={{ color: '#DC2626', fontSize: 12 }}
                    />
                    <Typography style={{marginTop: 16, ...styles.textLabel}}>{configJSON.titlePassword}</Typography>
                    <Input
                      data-test-id={"txtInputPassword"}
                      secureTextEntry={this.state.enablePasswordField}
                      placeholder={configJSON.placeHolderPassword}
                      onChangeText={handleChange('password')}
                      onBlur={() => setFieldTouched("password")}
                      error={(errors.password && touched.password) ?  errors.password : undefined}
                      errorStyle={{ color: '#DC2626', fontSize: 12 }}
                      rightIcon={
                        <Button
                          data-test-id="btnTogglePassword"
                          aria-label="toggle password visibility"
                          onPress={this.handleClickShowPassword}
                          style={[styles.removeBackground]}
                          icon={this.state.enablePasswordField ? (
                            <VisibilityOffOutlinedIcon style={{width: 24, height: 24, color: '#C3CBC0'}}/>
                          ) : (
                            <VisibilityOutlinedIcon style={{width: 24, height: 24, color: '#C3CBC0'}}/>
                          )}
                        />
                      }
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      style={{ margin: 5, marginLeft: -12 }}
                      textStyle={{...styles.checkBoxText, fontWeight: '400', fontFamily: "Lato"}}
                      title="Remember me"
                      checkedIcon={<img style={{width: 20, height: 20}} src={imgCheckbox}/>}
                      onPress={this.setRememberMe}
                      checked={this.state.checkedRememberMe}
                    />
                    <Button
                      data-test-id={"forgot-password-btn"}
                      style={[styles.removeBackground, { marginRight: '-10px' }]}
                      textStyle={{fontSize: 14, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      onPress={() => {this.goToForgotPassword()}}
                      text="Forgot password?"
                    />
                  </div>
                  <Box style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <Button
                      data-test-id={"btnLogin"}
                      text={configJSON.btnTxtLogin}
                      loading={this.state.loading}
                      textStyle={{fontFamily: "Lato", color: isValid ? "#fff" :  '#7C8E76' }}
                      onPress={() => {
                        if(isValid){
                          handleSubmit()
                        }
                      }}
                      style={{backgroundColor: isValid ? '#7C8E76' : '#D6DCD5'}}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography style={{fontFamily: "Lato"}}>
                      {configJSON.labelOr}
                    </Typography>
                    <Button
                      data-test-id={"btn-register"}
                      style={styles.removeBackground}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      text={configJSON.btnTxtRegister}
                      onPress={this.goToSignUp}
                    />
                  </Box>
                </FormContainer>
              )}
            </Formik>
    )
  }
  renderModalInformation = () => {
    return <ModalInformation isOpen={this.state.isOpenModalInformation} handleClose={this.handleCloseInformationModal}/>
  }
  renderCaregiverLogin = () => {
    const isValid = this.state.phoneNumber !== ""
    return (

                <FormContainer data-test-id="phone-login-form" style={{paddingBottom: 130}}>
                  <div
                  style={{marginTop: 27,}}
                  >
                    <Typography style={styles.textLabel}>{configJSON.titlePhone}</Typography>
                    <Input
                      data-test-id={"txtPhoneNumber"}
                      keyboardType={configJSON.firstInputKeyboardStyle}
                      value={this.state.phoneNumber}
                      placeholder={configJSON.placeHolderPhone}
                      onChangeText={(value) => {
                        if (/^\d*$/.test(value)) {
                          this.setState({ phoneNumber: value });
                        }
                      }}
                      error={this.state.phoneNumberError ?? undefined}
                      errorStyle={{ color: '#DC2626', fontSize: 12 }}
                    />
                  </div>
                  <Box style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <Button
                      data-test-id={"btnLoginPhoneNumber"}
                      text={configJSON.btnTxtLogin}
                      loading={this.state.loading}
                      textStyle={{fontFamily: "Lato", color: isValid ? "#fff" :  '#7C8E76' }}
                      onPress={() => {
                        if(isValid){
                          this.handleCaregiverLogin()
                        }
                      }}
                      style={{backgroundColor: isValid ? '#7C8E76' : '#D6DCD5'}}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography style={{fontFamily: "Lato"}}>
                      {configJSON.labelOr}
                    </Typography>
                    <Button
                      data-test-id={"btn-register"}
                      style={styles.removeBackground}
                      textStyle={{fontSize: 16, fontWeight: '700', color: '#7C8E76', fontFamily: "Lato"}}
                      text={configJSON.clickInfo}
                      onPress={this.handleOpenInformationModal}
                    />
                  </Box>
                </FormContainer>
    )
  }
  renderToast = () => {
    return (
      <Toast data-test-id="toast-message" textMessage={this.state.errorMessage ?? ""} isShow={!!this.state.errorMessage} mode="error" width={401}/>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', position: 'relative'}}>
        <UnauthorizedHeader/>
        {this.renderModalInformation()}
        {this.renderToast()}
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', paddingTop: 10, paddingBottom: 10}}>
          <Box sx={styles.formContainer}>
            <Box flexDirection='row' style={styles.buttonRoutingContainer}>
              <Button
                data-test-id={"btnHospice"}
                text='Hospice'
                onPress={this.changeToHospiceLogin}
                textStyle={{ fontFamily: "Lato", color: this.state.isCaregiverLogin ? "#96A591" :  "#586554", fontWeight: "700" }}
                style={this.state.isCaregiverLogin ? styles.buttonInactive : styles.buttonActive}
              />
              <Button
                data-test-id={"btnCaregiver"}
                text='Caregiver'
                onPress={this.changeToCaregiverLogin}
                textStyle={{ fontFamily: "Lato", color: this.state.isCaregiverLogin ? "#586554" : "#96A591", fontWeight: "700" }}
                style={this.state.isCaregiverLogin ? styles.buttonActive : styles.buttonInactive}
              />
            </Box>
            <Typography style={{...styles.mainLabel, textAlign: 'center', marginTop: 20, fontFamily: "Lato"}}>{configJSON.labelTitle}</Typography>
            {this.state.isCaregiverLogin ? this.renderCaregiverLogin() : this.renderHospiceLogin()}
          </Box>
            <ImageContainer>
              <img style={{...styles.image, objectFit: 'cover',}} src={require('../assets/loginImage.png')}/>
            </ImageContainer>
        </div>
        <UnauthorizedFooter data-test-id="footer" goToPP={this.goToPrivacyPolicy} goToTAC={this.goToTermsAndCondition}/>
      </div>
      // Customizable Area End
    );
  }

}

  // Customizable Area Start
  const styles = {
   
    removeBackground: {
      backgroundColor: "transparent" 
    },
    buttonActive: {
      borderRadius: 2, minWidth: 98, minHeight: 52, paddingVertical: '14px', paddingHorizontal: 0, backgroundColor: '#F2F4F1', borderBottomWidth: 1, borderBottomColor: "rgba(124, 142, 118, 0.50)"
    },
    buttonInactive: {
      borderRadius: 2, minWidth: 98, minHeight: 52, paddingVertical: '14px', paddingHorizontal: 0, backgroundColor: '#FFF', borderWidth: 1, borderColor: "#F2F4F1"
    },
    mainLabel: {
      fontSize: 24,
      fontWeight: "700",
      letterSpacing: -0.12,
      color: "#343C32"
    },
    textLabel: {
      fontSize: 14,
      fontWeight: '700',
      color: '#343C32',
      fontFamily: "Lato"
    },
    buttonRoutingContainer: {
      display: 'flex', columnGap: 2, alignSelf: 'center'
    },
    image: {
      width: '100%',
      borderBottomLeftRadius: 45,
      aspectRatio: 1
    },
   
    errorStyle: {
      color: "#DC2626",
    },
    checkBoxText: {
      fontSize: 16,
      color: "#343C32",
    },
    formContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      height: "100%",
    
    },
    buttonsWrapper: {
      flex: 1,
    },
  }
  const FormContainer = styled(Box)({
    boxSizing: 'border-box',
    flexDirection: 'column',
    width: 360,
    paddingLeft: "16px",
    display: 'flex',
  paddingRight: "16px",
    alignSelf: 'center',
    "@media (max-width: 414px)": {
      width: "100%"
    },
  });
  const ImageContainer = styled(Box)({
    flex: 1,
    display: 'flex',
    '@media (max-width: 1180px)' : {
    display: 'none'
    }
  });
 

  // Customizable Area End