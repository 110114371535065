import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export interface IProject {
  id:         string;
  type:       string;
  attributes: {
    name:           string;
    stripe_prod_id: string;
    plans:          IPlan[];
  };
}
export interface IPlan {
  id:               number;
  price:            string;
  currency:         string;
  stripe_price_id:  string;
  interval:         string;
  discount_details: string;
  product_id:       number;
  name?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activePatientAverageIndex: number
  currentUserData: string
  loading: boolean
  listProject: IProject[]
  token: string
  stripeClientSecret: string | null
  isShowModalPayment: boolean
  selectedPlan: IPlan | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSubscriptionCallId: string = ""
  apiSubscribePlanCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activePatientAverageIndex: 0,
      currentUserData: "",
      loading: false,
      listProject: [],
      token: "",
      stripeClientSecret: null,
      isShowModalPayment: false,
      selectedPlan: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseMessage(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleGetToken()
  }
  handleGetToken = () => {
    let token =  localStorage.getItem("token") ?? ""
    this.setState({ token: token}, () => {
      this.handleGetListSubscription()
    });
  }
  handleGetListSubscription = () => {
     const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSubscribePlan = (stripePriceId: string, planId: string, plan: IPlan) => {
    this.setState({
      loading: true,
      selectedPlan: plan
    })
    const header = {
     "token": this.state.token
   };
   const formData = new FormData();
   formData.append("subscription[stripe_price_id]", stripePriceId)
    formData.append("subscription[plan_id]", planId)
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   this.apiSubscribePlanCallId = requestMessage.messageId;
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.subscribePlanEndpoint
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.exampleAPiMethod
   );
   requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 }
  handleResponseMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.apiSubscriptionCallId) {
      if (responseJson.data) {
        this.setState({
          loading: false,
          listProject: responseJson.data
        })
      }
    }
    if (apiRequestCallId === this.apiSubscribePlanCallId) {
      if (responseJson.client_secret) {
        this.setState({
          loading: false,
          stripeClientSecret: responseJson.client_secret,
          isShowModalPayment: true,
        })
      }
    }
    
  }


  goToTermsAndCondition = () => {
    const messageTAC: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    messageTAC.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Terms and Conditions");
    messageTAC.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageTAC);
  }
  goToContactUs = () => {
    this.props.navigation.navigate("ContactUs")
  }
  onCloseModal = () => {
    this.setState({
      isShowModalPayment: false
    })
  }
  // Customizable Area End
}
