import React, { CSSProperties, ReactNode } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '20px',
})

const Letter = styled(Avatar)({
  backgroundColor: '#D6DCD5', 
  borderRadius: '8px', 
  padding: '10px', 
  color: '#586554', 
  fontFamily: 'Lato', 
  fontSize: '36px', 
  fontWeight: '700'
})

const FlexCol = styled(Box)({
  display: 'flex', 
  flexDirection: 'column', 
})

const ContentContainer = styled(FlexCol)({
  padding: '0 20px', 
  gap: '20px'
})

const DescContainer = styled(Box)({
  display: 'inline-flex',
  gap: '35px'
})

const NormalText = styled(Typography)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32'
});

const PrimaryHeadingText = styled(NormalText)({
  fontSize: '26px', 
  fontWeight: '700',
  color: '#586554'
});

type IItemWithAnnotationProps = | {
  withOrdering: true;
  order: string;
  orderStyles?: CSSProperties;
  main?: string;
  annotationList: ReactNode;
  descImageUrl?: string;
  containerStyles?: CSSProperties;
} | {
  withOrdering?: false;
  main?: string;
  annotationList: ReactNode;
  descImageUrl?: string;
  containerStyles?: CSSProperties;
}

const ItemWithAnnotation = (props: IItemWithAnnotationProps) => (
  <Container sx={{ ...props.containerStyles }}>
    {props.withOrdering && <Letter sx={{ ...props.orderStyles }}>{props.order}</Letter>}
    <ContentContainer>
      {props?.main && <PrimaryHeadingText>{props?.main}</PrimaryHeadingText>}
      <DescContainer>
        {props.descImageUrl && <img src={props.descImageUrl} alt='Desc Img' />}
        <FlexCol sx={{ gap: 1.5 }}>
          {props.annotationList}     
        </FlexCol>
      </DescContainer>
    </ContentContainer>
  </Container>
);

export default ItemWithAnnotation;
