import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box';

export default function UnauthorizedFooter({goToTAC, goToPP, goToCU} : {goToTAC: () => void, goToPP?: () => void, goToCU?: () => void}) {
  return (
    <Container >
      <ContentContainer sx={{ justifyContent: goToCU ? "end" : "start"}}>

      <Text1 data-test-id="btnTAC" onClick={goToTAC}>Terms and Conditions</Text1>

      {goToPP && <Text data-test-id="btnPP" onClick={goToPP}>Privacy Policy</Text>}
      {goToCU && <Text data-test-id="goToCU" onClick={goToCU}>Contact Us</Text>}
      </ContentContainer>
    </Container>
  );
}
const Container = styled(Box)({
  background: '#7C8E76',
  width: '100%',
});
const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  paddingRight: 130,
  paddingLeft: 130,

  '@media (max-width: 600px)': {
    paddingLeft: 16,
    paddingRight: 16,
  }
});
const Text = styled(Typography)({
  color: '#FFFFFF',
  fontSize: 24,
  fontWeight: 700,
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 16,
  cursor: 'pointer',
  fontFamily: "Lato",
  '@media (max-width: 600px)': {
    fontSize: 16,
  }
})
const Text1 = styled(Typography)({
  color: '#FFFFFF',
  fontSize: 24,
  fontWeight: 700,
  marginTop: 32,
  marginBottom: 32,
  cursor: 'pointer',
  fontFamily: "Lato",
  '@media (max-width: 600px)': {
    fontSize: 16,
  }
})
