import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const toiletManImage = require('./toilet_man.svg');
const howToTakeImage = require('./how_to_take.svg');
const whenItWorkImage = require('./when_they_work.svg');

interface MedicationPainProps {
}

export default function MedicationPain(props: MedicationPainProps) {
  const renderArrowRight = (color: string) => {
    return <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.997666 19.0147C1.48767 19.5047 2.27767 19.5047 2.76767 19.0147L11.0777 10.7047C11.4677 10.3147 11.4677 9.68469 11.0777 9.29469L2.76767 0.984688C2.27767 0.494687 1.48767 0.494687 0.997666 0.984688C0.507666 1.47469 0.507666 2.26469 0.997666 2.75469L8.23767 10.0047L0.987666 17.2547C0.507666 17.7347 0.507666 18.5347 0.997666 19.0147Z" fill={color}/>
    </svg>
    
  }
  const renderFirstImage = () => {
    return <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M73.8104 76.9999C93.6914 76.9999 109.808 60.8831 109.808 41.0019C109.808 21.1208 93.6914 5.00391 73.8104 5.00391C53.9293 5.00391 37.8125 21.1208 37.8125 41.0019C37.8125 60.8831 53.9293 76.9999 73.8104 76.9999Z" fill="#EBEDF0"/>
    <path d="M72.9768 93.1931H72.957C73.9308 89.9893 75.0647 87.0915 76.2166 84.2656H76.2346C80.5364 86.4255 86.0261 86.1555 90.0218 85.4896V103.093C90.0218 107.034 93.1716 112.992 93.1716 112.992H75.6227C74.3888 106.444 73.5056 99.8352 72.9768 93.1931Z" fill="white"/>
    <path d="M24.8568 79.0676C33.4423 75.0179 56.6249 64.3984 63.0145 64.3984C66.3983 64.3984 68.4142 65.8564 68.4142 67.9982C68.4142 79.2476 27.6646 94.9067 17.8192 96.9226C17.2931 97.0439 16.7568 97.1162 16.2173 97.1386C10.7097 97.1386 5.41797 90.605 5.41797 86.4472C5.41797 82.2894 7.90182 75.6478 9.01775 72.39L11.8076 65.1724C16.2906 69.0165 21.9127 71.2806 27.8086 71.616C27.0077 74.1686 26.0211 76.6591 24.8568 79.0676Z" fill="white"/>
    <path d="M76.1978 84.268C75.0459 87.0939 73.9119 89.9917 72.9382 93.1955C71.0449 99.622 70.1233 106.295 70.2042 112.994H16.2074C16.4362 107.611 16.971 102.244 17.8093 96.9213C27.6547 94.9054 68.4043 79.2463 68.4043 67.9969C68.4043 65.8551 66.3884 64.3971 63.0046 64.3971C56.615 64.3971 33.4324 75.0166 24.8469 79.0663C26.0112 76.6578 26.9978 74.1673 27.7987 71.6147C21.9028 71.2793 16.2806 69.0152 11.7976 65.1711V65.1531C10.8085 64.3681 9.87626 63.514 9.00781 62.5972C9.00781 62.5972 22.291 37.1826 34.2063 35.5987C37.2377 35.0675 40.3495 35.2212 43.3138 36.0487C43.3138 36.0487 48.3535 37.5786 49.9014 38.0106C51.3593 42.0604 54.5091 46.3981 61.2047 46.3981C63.6969 46.3977 66.1589 45.853 68.4188 44.8022C70.6786 43.7514 72.6817 42.2198 74.2881 40.3145H74.3079L79.2036 42.7983C80.6975 43.5543 90.003 50.4479 91.8029 53.7417C93.6028 57.0355 95.4027 84.196 95.4027 84.196C93.6387 84.7659 91.8335 85.1992 90.003 85.492C86.0072 86.1579 80.5176 86.4279 76.2158 84.268H76.1978Z" fill="#94CE81"/>
    <path d="M79.2155 28.3971C78.9939 32.8118 77.266 37.0172 74.3198 40.3124H74.3C72.6936 42.2178 70.6905 43.7494 68.4306 44.8002C66.1708 45.851 63.7088 46.3956 61.2166 46.3961C54.521 46.3961 51.3712 42.0583 49.9133 38.0085C49.1236 35.7863 48.6864 33.4542 48.6173 31.0969C44.6756 28.613 46.2595 21.1975 48.6173 21.1975C49.4576 21.1034 50.3081 21.2185 51.0932 21.5325C51.8782 21.8465 52.5735 22.3497 53.1171 22.9974C56.2899 22.0346 59.1076 20.1561 61.2166 17.5977C61.2166 17.5977 67.7322 28.3971 79.2155 28.3971Z" fill="white"/>
    <path d="M68.4199 5.00391C79.4893 5.00391 80.1192 20.987 88.2187 19.4031C88.2187 19.4031 85.4109 28.4026 79.2193 28.4026C67.736 28.4026 61.2203 17.6032 61.2203 17.6032C59.1114 20.1617 56.2936 22.0402 53.1208 23.0029C52.5772 22.3553 51.882 21.8521 51.0969 21.5381C50.3119 21.224 49.4614 21.1089 48.6211 21.203C48.6211 3.20401 61.2203 6.80381 61.2203 6.80381C61.2203 6.80381 62.8762 5.00391 68.4199 5.00391Z" fill="#979FAF"/>
    <path d="M88.2187 19.4031C80.1192 20.987 79.4893 5.00391 68.4199 5.00391C62.8762 5.00391 61.2203 6.80381 61.2203 6.80381C61.2203 6.80381 48.6211 3.20401 48.6211 21.203" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M74.3281 40.3216C77.2743 37.0264 79.0022 32.821 79.2238 28.4062" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M88.215 19.3976C88.215 19.3976 85.4071 28.3971 79.2155 28.3971C67.7322 28.3971 61.2166 17.5977 61.2166 17.5977C59.1076 20.1561 56.2899 22.0346 53.1171 22.9974C52.5735 22.3497 51.8782 21.8465 51.0932 21.5325C50.3081 21.2185 49.4576 21.1034 48.6173 21.1975C46.2595 21.1975 44.6756 28.613 48.6173 31.0969C48.6864 33.4542 49.1236 35.7863 49.9133 38.0085C51.3712 42.0583 54.521 46.3961 61.2166 46.3961C63.7088 46.3956 66.1708 45.851 68.4306 44.8002C70.6905 43.7494 72.6936 42.2178 74.3 40.3124" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M76.2227 84.2627C80.5244 86.4226 86.0141 86.1526 90.0098 85.4866C91.8403 85.1938 93.6455 84.7606 95.4095 84.1907C95.4095 84.1907 93.6096 57.0482 91.8097 53.7364C90.0098 50.4245 80.7044 43.5489 79.2105 42.793" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.7976 65.1531C10.8085 64.3681 9.87626 63.514 9.00781 62.5972C9.00781 62.5972 22.291 37.1827 34.2063 35.5987C37.2377 35.0675 40.3495 35.2212 43.3138 36.0487" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.0211 59.0039L28.8215 71.6032C28.4795 71.6212 28.1375 71.6212 27.8135 71.6212C21.9177 71.2857 16.2955 69.0217 11.8125 65.1776" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.617 80.5975C21.617 80.5975 22.8229 80.0036 24.8568 79.0676C33.4423 75.0179 56.6249 64.3984 63.0145 64.3984C66.3983 64.3984 68.4142 65.8564 68.4142 67.9982C68.4142 79.2476 27.6646 94.9067 17.8192 96.9226C17.2931 97.0439 16.7568 97.1162 16.2173 97.1386C10.7097 97.1386 5.41797 90.605 5.41797 86.4472C5.41797 82.2894 7.90182 75.6478 9.01775 72.39" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.8073 71.6172C27.0064 74.1697 26.0198 76.6602 24.8555 79.0688" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.207 112.999C16.4358 107.615 16.9707 102.249 17.8089 96.9258" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M82.7995 64.3984C81.3844 71.2582 79.1673 77.9276 76.1939 84.2693C75.0419 87.0952 73.908 89.993 72.9342 93.1968C71.0409 99.6233 70.1194 106.297 70.2002 112.996" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M75.6224 113.002C74.3886 106.454 73.5054 99.8452 72.9766 93.2031" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M90.0117 91.6211V103.104C90.0117 107.046 93.1615 113.004 93.1615 113.004" stroke="#092705" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.7969 61.0121C55.0595 59.8444 56.6147 59.0404 58.2975 58.6855C59.9802 58.3306 61.7277 58.4379 63.3543 58.9962" stroke="white" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M58.0625 85.955C59.7051 86.5278 61.4906 86.5438 63.1432 86.0005C64.7959 85.4572 66.2236 84.3849 67.206 82.9492" stroke="white" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.4062 91.6095C53.0714 93.5196 56.3095 94.4612 59.5834 94.2778C62.8572 94.0945 65.97 92.7973 68.4052 90.6016" stroke="white" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M45.0117 59.7575C46.088 57.4779 47.7461 55.5222 49.819 54.0876C51.8919 52.653 54.3064 51.7901 56.819 51.5859" stroke="white" stroke-width="2.74993" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  }
  const toiletManImage = require('./toilet_man.svg');
  const renderImageRowItem = () => {
    return <img src={toiletManImage} width={120} height={120}/>
    
  }
  const navbarStyles = {
    dash: {
      color: "#96A591",
      fontStyle: "Lato"
    },
  };
  const {
  } = props;
  return (
    <>
    <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "10px", marginBottom: "10px",gap:"10px" , alignItems: 'center'}}>
    <ArrowBackIcon />
    <NavigationText sx={navbarStyles.dash}>Dashboard</NavigationText>
    <ChevronRightIcon sx={navbarStyles.dash} />
    <NavigationText sx={navbarStyles.dash}>Medications</NavigationText>
    <ChevronRightIcon sx={navbarStyles.dash} />
    <NavigationText sx={navbarStyles.dash}>Pain</NavigationText>
    <ChevronRightIcon sx={navbarStyles.dash} />
    <NavigationText sx={navbarStyles.dash}>Opioid Pain Meds</NavigationText>
    <ChevronRightIcon sx={navbarStyles.dash} />
    <NavigationText style={{fontWeight: "700", color: "#343C32"}}>Understanding Opioid Pain Meds</NavigationText>
  </Box>
   <div style={{display: 'flex', flexDirection: 'column', rowGap: 20}}>
    <div style={{display: 'flex', flexDirection: 'column', background: '#F2F4F1',padding: 40, borderRadius: 8,  paddingLeft: 200, paddingRight: 200,}}>
      <LatoText style={{fontWeight: "700", fontSize: 26, textAlign: 'center'}}>
      <span style={{color: "#343C32"}}>Understanding </span>
      <span style={{color: "#DC2626"}}>Opioid Pain</span>
      <span style={{color: "#343C32"}}> Medications</span>
      </LatoText>
      <LatoText
      style={{ fontSize: 22, color: "#586554", marginTop: 16, textAlign: 'center'}}
      >Common names: Morphine, Norco, Oxycodone, Hydrocodone, MS Contin</LatoText>
      <LatoText style={{fontWeight: "700", fontSize: 26, color: "#586554", marginTop: 40, textAlign: 'center'}}>How will this medication help</LatoText>
      <div style={{display: 'flex', flexDirection: 'row', columnGap: 20, justifyContent: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column', padding: 10, paddingTop: 20, paddingBottom: 20, border: "1px solid #D6DCD5", borderRadius: 8, width: 280, alignItems: 'center'}}>
            {renderFirstImage()}
            <LatoText style={{fontSize: 22, textAlign: 'center'}}>It decreases pain</LatoText>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', padding: 10, paddingTop: 20, paddingBottom: 20, border: "1px solid #D6DCD5", borderRadius: 8, width: '280px', alignItems: 'center'}}>
            {renderFirstImage()}
            <LatoText  style={{fontSize: 22, textAlign: 'center'}}>With less pain you can be more active</LatoText>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', padding: 10, paddingTop: 20, paddingBottom: 20, border: "1px solid #D6DCD5", borderRadius: 8, width: 280, alignItems: 'center'}}>
            {renderFirstImage()}
            <LatoText  style={{fontSize: 22, textAlign: 'center'}}>It can decrease shortness of breath</LatoText>
        </div>
      </div>
      </div>
     

      <div style={{display: 'flex', flexDirection: 'column', background: '#343C32', padding: 40, paddingLeft: 200, paddingRight: 200, borderRadius: 8, rowGap: 40}}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#fff", }}>How it works</LatoText>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'left', columnGap: 35, marginTop: 32, alignItems: 'center' }}>
            <img src={howToTakeImage} style={{ width: 120, height: 120 }} />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 10, justifyContent: 'center' }}>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Take it by mouth</DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Generally can be taken every 4-6 hours depending on medication</DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>Your nurse will let you know how many times per day it can be taken</DescriptionText>
          </RowItemTop>
            </div>
          </div>
        </div>
        <div style={{background: "#586554", height: 1}}/>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#fff", }}>When will it work</LatoText>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'left', width: 1000, columnGap: 35, marginTop: 32, alignItems: 'center'  }}>
            <img src={whenItWorkImage} style={{ width: 120, height: 120 }} />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 10, justifyContent: 'center' }}>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>It can start working in about <span style={{fontWeight: "700"}}>30 MINUTES</span></DescriptionText>
          </RowItemTop>
            <RowItemTop>
          {renderArrowRight("#fff")}
              <DescriptionText style={{ color: "#fff" }}>There is a liquid preparation of morphine that can work faster</DescriptionText>
          </RowItemTop>
            </div>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', columnGap: 20, paddingLeft: 130, paddingRight: 130}}>
      <div style={{display: 'flex',flex: 1, flexDirection: 'column', background: "#F2F4F1", padding: 40, paddingLeft: 20, paddingRight: 20, gap: 20, borderRadius: 8}}>
        <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#586554", textAlign: "center"}}>Common side effects</LatoText>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32" }}>Constipation - Your nurse will tell you which laxative you should be taking</DescriptionText>
        </RowItem>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32" }}>Sleepiness and confusion - This should improve with time</DescriptionText>
        </RowItem>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32" }}>Do not drink alcohol or drive a car while using</DescriptionText>
        </RowItem>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32", }}>Itching is not uncommon. Generally it does not mean an allergic reaction. Ask your nurse about medications that can help</DescriptionText>
        </RowItem>
        </div>
        <div style={{display: 'flex',flex: 1, flexDirection: 'column', background: "#FEF2F2", padding: 40, paddingLeft: 20, paddingRight: 20, gap: 20, borderRadius: 8, border: "2px dashed #F87171"}}>
        <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#586554", textAlign: "center"}}>Serious side effects - <span style={{color: "#DC2626"}}>Call Hospice</span></LatoText>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32" }}>It is hard to wake up or stay awake</DescriptionText>
        </RowItem>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32" }}>A worrisome decrease in breathing or the number of breaths</DescriptionText>
        </RowItem>
        <RowItem>
        {renderImageRowItem()}
        <DescriptionText style={{ color: "#343C32" }}>Painful stomach cramps or inability to have a bowel movement</DescriptionText>
        </RowItem>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', background: "#F0E5FF", border: "2px dashed #6200EA", borderRadius: 8, padding: 20, paddingLeft: 130, paddingRight: 130, marginLeft: 130, marginRight: 130, marginBottom: 130, justifyContent: 'center', alignItems: 'center'}}>
      {renderArrowRight("#343C32")}
      <LatoText style={{textTransform: 'uppercase', fontSize: 22, fontWeight: '700'}}>LAXATIVES SHOULD ALWAYS BE TAKEN IF USING OPIOID PAIN MEDICATIONS</LatoText>
      </div>
      
   </div>
   </>
  );
}
const RowItemTop = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: 10
});
const RowItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
  padding: 10,
  background: "#fff",
  border: "1px solid #D6DCD5",
  borderRadius: 8,
  alignItems: "center",
  columnGap: 20
});
const LatoText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400"
});
const DescriptionText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400",
  fontSize: 22,
});

const NavigationText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
  fontSize: 22,
});