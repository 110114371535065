// Customizable Area Start

import React, { Component } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IContentData, IImage, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";
import {
  Grid,
  Box, Typography,
} from "@mui/material";

interface ISectionData  {
  name: string;
  definition: string;
  images: IImage[];
  children? : { definition: string; images: IImage[] }[];
}
const navbarStyles = {
  main: {
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  dash: {
    color: "#96A591",
    fontStyle: "Lato"
  },
};

const medicationStyles = {
  medi: {
    backgroundColor: "#F2F4F1",
    padding: "40px 0px 40px 0px",
    marginTop:"10px"

  }
}

export default function StopMedication({data}:{data:IContentData}) {
  const {id, type, attributes} = data; 
  
  const title = attributes?.title?.title||"";
  const desc= attributes?.title?.desc||""
  const terms = attributes?.terms; 
  const image= attributes?.title?.images
  
  return (
    <Box sx={navbarStyles.main}>
    <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "10px",gap:"10px" }}>
      <ArrowBackIcon />
      <Typography sx={navbarStyles.dash}>Dashboard</Typography>
      <ChevronRightIcon sx={navbarStyles.dash} />
      <Typography sx={navbarStyles.dash}>Medications</Typography>
      <ChevronRightIcon sx={navbarStyles.dash} />
      <Typography>Stopping Medications</Typography>
    </Box>
    <Box sx={medicationStyles.medi}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={4} container justifyContent="center">
        <img src={(image?.[0]?.url ?? "img")} alt='image1' />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography sx={{ color: "#DC2626", fontSize: "26px", textAlign: "center",fontWeight:"700" }}>{title}</Typography>
          <Typography sx={{ color: "#586554", fontSize:"22px" }} dangerouslySetInnerHTML={{ __html: desc }}>
           
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} container justifyContent="center">
          <img src={(image?.[0]?.url ?? "img")} alt='image2' />
        </Grid>
      </Grid>
      <Box sx={{ paddingLeft: { xs: "20px", sm: "185px" }, marginTop: "20px" }}>
          {terms?.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', mb: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: "10px" }}>
                <Box sx={{ maxWidth: '40px', maxHeight: '62px', width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#D6DCD5" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '24px' }}>
                    {index + 1}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" sx={{ fontWeight: '700',fontSize:"26px", color: "#586554" }}>
                  {item?.term}
                </Typography>
              </Box>
              <Box sx={{ paddingLeft: "55px" }}>
                {/* {item.details.map((detail, detailIndex) => ( */}
                  <Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', mb: 1 }}>
                    <ChevronRightIcon sx={{ color: 'text.secondary' }} />
                    <Typography variant="body2" sx={{ ml: 1,fontSize:"22px",color:"#586554",listStyle:"none" }} dangerouslySetInnerHTML={{ __html: item?.definition}}>
                    </Typography>
                  </Box>
                {/* ))} */}
              </Box>
            </Box>
          ))}
        </Box>
    </Box>
  </Box>

  )
}

// Customizable Area End


