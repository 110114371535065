import React, {useEffect, useState} from 'react'
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const config = require("../../framework/src/config");
import {Button } from '@builder/component-library';
type Props = {
  onClose: () => void
  isShowModal: boolean
  plan: any
}
function CheckoutForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentUserData = localStorage.getItem("user_data") ?? ""
  const getHospiceName = () => {
    if(currentUserData && currentUserData !== ""){
      const currentData = JSON.parse(currentUserData)
      return currentData?.data?.attributes.hospice_name ?? "name"
    }
    return "Not set name"
  }
  const getHospiceLogo = () => {
    if(currentUserData && currentUserData !== ""){
      const currentData = JSON.parse(currentUserData)
      return `${config.baseURL}/${currentData?.data?.attributes.hospice_logo}` ?? require('./hospice_logo.png')
    }
    return require('./hospice_logo.png')
  }
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: any) => {
      console.log('paymentIntent.status',paymentIntent.status)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${config.baseURL.replace('ruby','react')}/dashboard`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message as string);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };
  const renderHeader = () => {
    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: "1px solid #E2E8F0", paddingTop: 24,paddingBottom: 24, paddingLeft: 40, paddingRight: 20}}>
        <Typography style={{fontFamily: "Lato", fontSize:20, fontWeight: "700", color: "#343C32"}}>Payment details</Typography>
      <IconButton onClick={props.onClose}>
      <CloseIcon/>

      </IconButton>
    </div>
  }
  const renderInformation = () => {
   return ( <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 24, paddingTop: 24, borderBottom: "1px solid #E2E8F0"}}>
      <img src={getHospiceLogo()} style={{width: 44, height: 44, borderRadius: 50, border: "1px solid #586554"}}/>
      <div style={{marginLeft: 10, display: 'flex', flexDirection: 'column', flex: 1}}>
        <Typography style={{fontFamily: "Lato", fontSize: 18, fontWeight: "700", color: "#586554"}}>{getHospiceName()}</Typography>
        <Typography style={{fontFamily: "Inter", fontSize: 12, fontWeight: "400", color: "#7C8E76"}}>
          {`${props.plan.interval} months (${props.plan.name})`}</Typography>
      </div>
      <Typography style={{fontFamily: "Inter", fontSize: 20, fontWeight: "700", color: "#343C32"}}>${props.plan.price}</Typography>
    </div>)
  }
  const renderTotalAmount = () => {
    return <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between',paddingBottom: 24, paddingTop: 24, borderTop: "1px solid #E2E8F0"}}>
        <Typography style={{fontFamily: "Lato", fontSize: 18, fontWeight: "700", color: "#343C32"}}>Total Amount</Typography>
        <Typography style={{fontFamily: "Inter", fontSize: 20, fontWeight: "700", color: "#343C32"}}>${props.plan.price}</Typography>
    </div>
  }

  return (
    <Modal
      open={props.isShowModal}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div style={{ width: '560px', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', borderRadius: 8, borderBottomRightRadius: 32 }}>
        {renderHeader()}
        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 40, paddingRight: 40,rowGap: 24}}>
          {renderInformation()}
          <div style={{overflowY: "scroll", overflowX: 'hidden', height: 450}}>
          <PaymentElement id="payment-element" options={{ layout: "tabs" }} />

          </div>
          {renderTotalAmount()}
        </div>
          <Button
            data-test-id={"button-submit"}
            style={{ backgroundColor: !(isLoading || !stripe || !elements) ? "#7C8E76" : "#D6DCD5",  marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 12, width: 512 }}
            textStyle={{ fontSize: 16, fontWeight: '700', color: !(isLoading || !stripe || !elements) ? '#fff' : "#7C8E76", fontFamily: "Lato" }}
            text="Pay now"
            onPress={handleSubmit}
            loading={isLoading}
          />
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
      </div>

    </Modal>
  );
}

export default CheckoutForm