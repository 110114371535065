Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.patchAPiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpingenerator";
exports.labelBodyText = "cfpingenerator Body";

exports.btnExampleTitle = "CLICK ME";
exports.boldTextHeader = "Add a New Patient";
exports.textHeader = ": Enter the patient's information and status and up to 3 caregivers. Each caregiver will automatically receive an email with the link to Partner for Care. The caregiver's phone number will be used as their unique pin to log in."
exports.patientInformation = "Patient Information";
exports.caregiverInformation = "Caregiver Information"
exports.labelPatientName = "Patient Name*"
exports.selectStatusOfPatient = "Status of Patient*"
exports.labelCaregiverName = "Caregiver Name*"
exports.labelRelationToPatient = "Relation to Patient"
exports.labelPhone = "Caregiver Phone Number*"
exports.labelEmail = "Caregiver Email*"
exports.placeholderPatientName = "Patient Name"
exports.placeholderCaregiverName = "Caregiver Name"
exports.placeholderRelationToPatient = "Relation of patient"
exports.placeholderPhone = "Caregiver phone number"
exports.placeholderEmail = "Caregiver email"
exports.btnTxtDelete = "Delete"
exports.btnTxtAddCaregiver = "+ Add Caregiver"
exports.textMaxCaregiver = "A maximum of 3 caregivers can be added to one patient."
exports.btnTxtSave = "Save"
exports.btnTxtCancel = "Cancel"
exports.addPatientApiEndPoint = "bx_block_cfpingenerator/add_patients"
exports.addCaregiverApiEndPoint = "bx_block_cfpingenerator/add_patients/"
exports.updatePatientApiEndPoint = "bx_block_cfpingenerator/caregivers/create_or_update"
exports.headerModalTitle = "Delete Caregiver"
exports.modalFirstLine = "Are you sure you want to delete the Caregiver:"
exports.modalSecondLine = "They will no longer be able to access Partner for Care."
exports.modalButtonRight = "Delete Account"
exports.modalConfirmButtonRight = "Log Out"
exports.modalButtonLeft = "Keep Account"
exports.patientEndpoint = "bx_block_cfpingenerator/add_patients"
exports.logoutCaregiverEndpoint = "bx_block_cfpingenerator/caregivers/logout_from_all_devices"
exports.textLogout = "Log Out of All Sessions"
exports.titleHeaderModalConfirm = "Log Out of All Sessions"
exports.modalConfirmFirstLine = "Are you sure you want to logout the Caregiver:"
exports.errorExistingCaregiver = "Caregiver already added to another patient and has access to the platform."
// Customizable Area End