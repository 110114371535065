import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'; 

type Props = {
  type?: "nurse" | "hospice"
}
export default function ContactHospice({type}: Props) {
  const currentUserData = localStorage.getItem("caregiver_data") ?? ""
  const currentData = JSON.parse(currentUserData)
  const patientData = currentData?.attributes
  const hospiceData = patientData?.hospice?.attributes
  const handleContactNumber = (contact: string) => {
    let value = contact
    value = value.replace(/\D/g, "");
    value = value.substring(0, 10);
  
    if (value.length > 3 && value.length <= 6)
      value = value.slice(0, 3) + "-" + value.slice(3);
    else if (value.length > 6)
      value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);
    return value;
  };
  const renderPhone = () => {
    return <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6332 19.9365C19.5546 18.8581 17.7998 18.8581 16.7214 19.9365L15.7069 20.951C15.7035 20.9544 15.3163 21.2932 14.5396 21.2932C13.6317 21.2932 12.0859 20.8296 9.87742 18.6211C7.74898 16.4927 7.25127 14.9828 7.20856 14.0911C7.16864 13.2566 7.51711 12.8284 7.55768 12.7815L8.56196 11.7772C9.08445 11.2548 9.37216 10.5602 9.37216 9.82127C9.37216 9.08244 9.0844 8.3878 8.56196 7.86537L6.65397 5.95733C5.57554 4.87883 3.82067 4.87878 2.74218 5.95733L1.72772 6.97178C1.70787 6.99163 1.68906 7.01247 1.67134 7.03423C1.62086 7.0963 0.435895 8.58484 0.502723 11.2471C0.540567 12.7545 0.968496 14.347 1.77459 15.9806C2.75568 17.9687 4.30772 20.0313 6.38754 22.1111C8.46736 24.1909 10.5299 25.7429 12.5181 26.724C14.1516 27.5301 15.7441 27.958 17.2515 27.9958C17.3244 27.9977 17.3965 27.9986 17.4677 27.9986C19.9942 27.9986 21.404 26.8763 21.4644 26.8272C21.4861 26.8095 21.507 26.7907 21.5268 26.7708L22.5412 25.7564C23.0637 25.234 23.3514 24.5394 23.3514 23.8005C23.3514 23.0617 23.0637 22.3671 22.5412 21.8446L20.6332 19.9365Z" fill="#DC2626"/>
    <path d="M21.3074 0C17.3428 0 14.1172 3.22552 14.1172 7.19026C14.1172 10.0529 15.8156 12.6261 18.3999 13.768V15.6475C18.3999 15.9412 18.557 16.2126 18.8118 16.3589C18.9383 16.4315 19.0793 16.4678 19.2202 16.4678C19.3632 16.4678 19.5061 16.4304 19.6338 16.3559L23.7412 13.9576C26.5899 12.9334 28.4978 10.2245 28.4978 7.19026C28.4978 3.22558 25.2722 0 21.3074 0ZM22.1278 8.01057V8.96645C22.1278 9.41948 21.7605 9.78677 21.3074 9.78677C20.8544 9.78677 20.4871 9.41948 20.4871 8.96645V8.01057H19.5312C19.0782 8.01057 18.7109 7.64329 18.7109 7.19026C18.7109 6.73723 19.0782 6.36995 19.5312 6.36995H20.4871V5.41406C20.4871 4.96103 20.8544 4.59375 21.3074 4.59375C21.7605 4.59375 22.1278 4.96103 22.1278 5.41406V6.36995H23.0837C23.5367 6.36995 23.904 6.73723 23.904 7.19026C23.904 7.64329 23.5367 8.01057 23.0837 8.01057H22.1278Z" fill="#DC2626"/>
    </svg>
    
  }

    return (
        <Toast style={{borderRadius: type === "nurse" ? "4px" : 0}} data-test-id="toast">
             {renderPhone()}
             {type === "nurse" ? <ToastNurseText>
              {"If the patient is experiencing any one of these issues, please call the Nurse at"}{" "}
              <ToastSubText>{hospiceData.phone_number ? handleContactNumber(hospiceData?.phone_number.toString()) : "123-456-7890"}</ToastSubText>
             </ToastNurseText> : <ToastText>
                { 'For urgent care or questions, please contact the Hospice at'}{" "}
                <ToastSubText>{hospiceData.phone_number ? handleContactNumber(hospiceData?.phone_number.toString()) : "123-456-7890"}</ToastSubText>
              </ToastText> }
             
            </Toast>
  );
}

const ToastIcon = styled("img")({});

const ToastText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontSize: 20,
  "@media (max-width: 600px)": {
    fontSize: 16,
  },
});
const ToastNurseText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontSize: 16,
  "@media (max-width: 600px)": {
    fontSize: 12,
  },
});

const ToastSubText = styled("span")({
  fontWeight: 800,
  color: "#DC2626",
});

const Toast = styled(Box)({
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    backgroundColor: "#FEE2E2",
    paddingLeft: "16px",
    paddingRight: "16px",
    boxSizing: "border-box",
    fontSize: "20px",
    borderRadius: 0,
    "@media (max-width: 734px)": {
      height: "64px",
      paddingRight: "8px",
      fontSize: "16px",
    },
  });

