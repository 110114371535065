import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Toast({textMessage, isShow, width, mode}: {textMessage: string, isShow: boolean, width?: number, mode?: 'success' | 'error'}) {
    const isSuccess = mode !== 'error'
  return (
    <Modal
            open={isShow}
            hideBackdrop
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                padding: 8,
                paddingRight: 16,
                width: width ?? 290,
                borderRadius: 8,
                position: 'absolute',
                top: 158,
                right: 36,
                boxShadow: '0px 6px 15px -3px #00000026',

                gap: "8px",
                background: "#FFFFFF"
            }}>
                {isSuccess ? <CheckCircleIcon style={{ color: "#34D399" }} /> :<CancelIcon style={{ color: "#DC2626" }}  />}
                <Typography style={{ fontFamily: "Inter",
        color: "#0F172A"}}>
                    {textMessage}
                </Typography>
            </div>
        </Modal>
  );
}
