import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import  {  createRef } from 'react';
import {  FormikProps } from 'formik';
export interface FormInformationValues {
  hospiceName: string,
  contactNumber: string,
  website: string,
  license: string,
  address: string,
  city: string,
  selectedState: string,
  postalCode: string,
  hospiceLogo: File | null,
  hospiceLogoError?: File | null,
  hospiceLogoUrl: string | null
}
export interface IFormChangeEmail {
  email: string,
  confirmEmail: string
}
export interface IFormChangePassword {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string,
  isShowCurrentPassword: boolean,
  isShowNewPassword: boolean,
  isShowConfirmNewPassword: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string
  loading: boolean
  // Customizable Area Start
  fetching: boolean
  activeTab: string
  initialInformationFormValues: FormInformationValues;
  currentUserId: string,
  successMessage: string,
  isShowToast: boolean,
  changingEmail: boolean
  isOpenModal: boolean
  isShowModalCancel: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  initialChangeEmailForm: IFormChangeEmail
  initialChangePasswordForm: IFormChangePassword
  apiDeleteAccountCallId: string = "";
  regexPass = /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])(?=.*[A-Z])[A-Za-z\d@$!%*?&]{8,}$/
  apiGetCurrentHospiceCallId: string = "";
  apiPatchHospiceCallId: string = "";
  apiRemoveLogoCallId: string = "";
  apiChangeEmailCallId: string = "";
  apiChangePasswordCallId: string = "";
  formikChangeEmailRef = createRef<FormikProps<IFormChangeEmail>>();
  formikChangePasswordRef = createRef<FormikProps<IFormChangePassword>>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.initialChangeEmailForm = {
      email: "",
      confirmEmail: ""
    }
    this.initialChangePasswordForm = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isShowCurrentPassword: false,
      isShowNewPassword: false,
      isShowConfirmNewPassword: false
    }
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token:"",
      loading: false,
      // Customizable Area Start
      fetching: false,
      activeTab: "Account Settings",
      initialInformationFormValues : {
        hospiceName: "",
        contactNumber: "",
        website: "",
        license: "",
        address: "",
        city: "",
        selectedState: "",
        postalCode: "",
        hospiceLogo: null,
        hospiceLogoUrl: null
      },
      currentUserId: "",
      successMessage: "",
      isShowToast: false,
      changingEmail: false,
      isOpenModal: false,
      isShowModalCancel: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token =  localStorage.getItem("token") ?? ""
      let userData =  localStorage.getItem("user_data") ?? ""
      const rowUserData = JSON.parse(userData)
      this.setState({ token: token, currentUserId: rowUserData?.data?.id }, () => {
        this.getHospiceData()
      });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseMessage(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }
  handleResponseMessage = (message: Message) => {
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if(errorReponse){
      this.setState({loading: false, fetching:false, changingEmail: false})
      this.parseApiCatchErrorResponse(errorReponse);
      return
    }

    this.handleSettingApiMessage(message)
    this.handleLoginAndSecurityApiMessage(message)
  }
  handleSettingApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiGetCurrentHospiceCallId) {
      const dataResponse = responseJson.data
      if (dataResponse) {
        const userAttribute = dataResponse.attributes
        const newUserData: FormInformationValues = {
          hospiceName: userAttribute.hospice_name,
          contactNumber: userAttribute.phone_number,
          website: userAttribute.website,
          license: userAttribute.license,
          address: userAttribute.address,
          city: userAttribute.city,
          selectedState: userAttribute.state,
          postalCode: userAttribute.postal_code,
          hospiceLogo: null,
          hospiceLogoUrl: userAttribute.hospice_logo ?? null,
        }
        this.setState({
          fetching: false,
          initialInformationFormValues: newUserData
        })
      }else{
        this.setState({
          fetching: false,
        })
      }
    }
    if (apiRequestCallId === this.apiPatchHospiceCallId) {
      const userData = responseJson
      localStorage.setItem("user_data", JSON.stringify(userData))
      this.showModalSuccess("Updated all information")
    }
    
  }
  handleLoginAndSecurityApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiChangeEmailCallId) {
      if(responseJson.errors){
        this.setState({
          changingEmail: false,
          loading: false,
        })
        this.formikChangeEmailRef.current?.setErrors({email: "This email has already been taken"})
        return
      }
      this.showModalSuccess("Email Updated")
    }
    if (apiRequestCallId === this.apiChangePasswordCallId) {
      if(responseJson.errors){
        this.setState({
          changingEmail: false,
          loading: false,
        })
        this.formikChangePasswordRef.current?.setErrors({currentPassword: responseJson.errors})
        return
      }
      this.showModalSuccess("Password Updated")
    }
    
  }
  showModalSuccess = (message: string) => {
    this.setState({
      isShowToast: true,
      successMessage: message,
      changingEmail: false,
      loading: false,
    })
    setTimeout(() => {
      this.setState({
        isShowToast: false
      })
    }, 3000);
  }
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }
  getErrorClassName = (errorData: string | null | undefined, touchedData: boolean | undefined) => {
    return errorData && touchedData ? "error" : ""
  }
  isErrorField = (errorData: string | null | undefined, touchedData: boolean | undefined) => {
    return errorData && touchedData
  }
  handleNavigateDeleteAccount = () => {
    this.props.navigation.navigate("DeleteAccount")
  }
  onChangeTab = (tab: string) => {
    this.setState({
      activeTab: tab
    })
  }
  getHospiceData = () => {
    this.setState({
      fetching: true
    })
    const webHeader = {
      "token": this.state.token
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCurrentHospiceCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetCurrentHospiceUrl + "/" + this.state.currentUserId
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
  }
  handleSaveHospiceData = (hospice: FormInformationValues) => {
    const formData = new FormData();
    formData.append("data[hospice_name]", hospice.hospiceName)
    formData.append("data[website]", hospice.website)
    formData.append("data[license]", hospice.license);
    formData.append("data[city]", hospice.city)
    formData.append("data[state]", hospice.selectedState)
    formData.append("data[postal_code]", hospice.postalCode)
    formData.append("data[address]", hospice.address)
    formData.append("data[phone_number]", hospice.contactNumber)
    if(!!hospice.hospiceLogo){
      formData.append("data[hospice_logo]", hospice.hospiceLogo)
    }else if(hospice.hospiceLogoUrl === null){
      this.handleRemoveLogo()
    }
    setTimeout(() => {
      this.handlePatchInformation(formData)
    }, 1000);
  }
  handlePatchInformation  = (formData: FormData): boolean => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPatchHospiceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetCurrentHospiceUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleRemoveLogo = () => {
    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiRemoveLogoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiRemoveLogoUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleChangeEmail = (value: IFormChangeEmail) => {
    this.setState({changingEmail: true})
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiChangeEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiUpdateEmailLogoUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({updated_email: value.email})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangePassword = (value: IFormChangePassword) => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      data: {
        old_password: value.currentPassword,
        new_password: value.newPassword,
        confirm_password: value.confirmNewPassword
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiChangePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiUpdatePasswordUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goBackDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }
  goBackSetting = () => {
    this.props.navigation.navigate("Settings2")
  }
  goToViewBilling = () => {
    this.props.navigation.navigate("ViewBilling")
  }
  handleCloseModal = () => {
    this.setState({isOpenModal: false})
  }
  handleOpenModal = () => {
    this.setState({isOpenModal: true})
  }
  handleCloseModalCancel = () => {
    this.setState({isShowModalCancel: false})
  }
  handleOpenModalCancel = () => {
    this.setState({isShowModalCancel: true})
  }
  // Customizable Area End
}
